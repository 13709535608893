import React, { useEffect, useRef } from 'react';

export default function MobileAds() {
    const banner = useRef(null);
  
    const setBannerRef = (node) => {
      banner.current = node;
    };
  
    const atOptions = {
      key : 'fd7338b2bfbc19a3a131e507c37c6e52',
          format : 'iframe',
          height : 300,
          width : 160,
          params : {}
    };
  
    useEffect(() => {
      if (!banner.current.firstChild) {
        const conf = document.createElement('script');
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://www.profitabledisplaynetwork.com/${atOptions.key}/invoke.js`;
        conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;
  
        if (banner.current) {
          banner.current.appendChild(conf);
          banner.current.appendChild(script);
        }
      }
    }, []);
    return <div ref={setBannerRef} />;
    
  }