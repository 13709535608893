import React, { useState } from 'react'
import { BsBookmarkStarFill, BsCaretLeft, BsCaretLeftFill, BsCaretRight, BsCaretRightFill } from 'react-icons/bs'
import { Movies } from '../../data/MoviesData'
import { Swiper, SwiperSlide } from 'swiper/react'
import Title from '../Title'
import { Autoplay, Navigation } from 'swiper'
import { FaHeart } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import Rating from '../Stars'
import Loader from '../Notifications/Loader'
import Empty from '../Notifications/Empty'
import { useDispatch, useSelector } from 'react-redux'
import { LikeMovie, MovieLiked } from '../../context/Functionalities'
import { AiOutlinePlayCircle } from 'react-icons/ai'

const SwiperTop = ({ prevEl, nextEl, movies }) => {
  const { isLoading } = useSelector((state) => state.userLikeMovie)
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.userLogin)

  //if liked movie
  const isLiked = (movie) => {
    return MovieLiked(movie)
  }
  //display text on hover
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  return (
    <Swiper
      navigation={{ nextEl, prevEl }}
      autoplay={true}
      speed={1000}
      loop={true}
      modules={[Navigation, Autoplay]}
      breakpoints={{
        0: {
          slidesPerView: 2,
          spaceBetween: 3
        },
        768: {
          slidesPerView: 4,
          spaceBetween: 5
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 6
        },
        1280: {
          slidesPerView: 4,
          spaceBetween: 6
        }
      }}>
      {
        movies.map((movie, index) => (
          <SwiperSlide key={index} 
          onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}
          
          >
            <div className='h-60 hover:border-b-4 hover:border-borderr transition duration-800 ease-in bg-dry rounded-lg overflow-hidden hover:scale-90 cursor-pointer'
            >
              <Link to={`/movie/${movie?._id}`} >
                <img src={movie?.titleImage ? `${movie.titleImage}` : '/images/user2.png'}
                  alt={movie.titleImage} className='w-full h-60 object-cover rounded-lg' />
              </Link>
              
                <Link to={`/movie/${movie?._id}`}>
                {isHovering && (
                  <div className='absolute flex justify-between items-center gap-2 bottom-0 right-0 left-0 bg-main bg-opacity-60 text-white px-4 py-3'>
                    <h3 className='font-semibold truncate'>{movie?.name}</h3>
                    <AiOutlinePlayCircle size={40} />
                  </div>
                   )}
                </Link>
             
              {/* 
              <div className='px-4 hoveres gap-6 text-center absolute bg-black bg-opacity-50 hover:bg-opacity-0 top-0 left-0 right-0 bottom-0 pt-40'>
                <div className='flex gap-2 text-star justify-center'>
                  <Rating value={movie?.rate} />
                </div>
              </div> */}
            </div>
          </SwiperSlide>
        ))
      }
    </Swiper>
  )
}

function TopRated({ movies, isLoading }) {
  const [nextEl, setNextEl] = useState(null)
  const [prevEl, setPrevEl] = useState(null)
  const classNames = "transitions text-sm rounded w-8 h-8 flex flex-col justify-center items-center bg-subMain text-black hover:text-white";
  return (
    <div className='my-16'>
      <Title title='Top Rated' Icon={BsBookmarkStarFill} />
      <div className='mt-10'>
        {
          isLoading ? <Loader />
            :
            movies?.length > 0 ?
              <SwiperTop nextEl={nextEl} prevEl={prevEl} movies={movies} />
              :
              <Empty message='No movies found' />
        }
        <div className='w-full px-1 flex flex-row justify-center items-center gap-6 pt-12 items-center'>
          <button className={classNames} ref={(node) => setPrevEl(node)}>
            <BsCaretLeftFill />
          </button>
          <button className={classNames} ref={(node) => setNextEl(node)}>
            <BsCaretRightFill />
          </button>
        </div>
      </div>
    </div >
  )
}

export default TopRated