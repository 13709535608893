import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaHeart, FaPlay } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { LikeMovie, MovieLiked } from '../context/Functionalities'
import { motion } from 'framer-motion'
import { BsPlayCircle } from 'react-icons/bs'
import FlexMovieItem from './FlexMovieItem'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { deleteAllLikedMoviesAction } from '../Redux/Actions/userAction'


function MovieCard({ movie }) {
    const navigate = useNavigate();
    const { isLoading } = useSelector((state) => state.userLikeMovie)
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.userLogin)

    //if liked movie
    const isLiked = (movie) => {
        return MovieLiked(movie);
    };
    //movie like
    const { isLoading: likeLoading } = useSelector(
        (state) => state.userLikeMovie
    );

    const {
        delError,
        isSuccess
    } = useSelector((state) => state.userDeleteAllFavs)

    const deleteFavsHandler = () => {
        dispatch(deleteAllLikedMoviesAction())
    }

    useEffect(() => {
        if (delError) {
            toast.error(delError)
            dispatch({ type: delError ? "USER_GET_FAVOURITE_MOVIE_RESET" : "USER_DELETE_FAVOURITE_MOVIES_RESET" })
        }

    }, [dispatch, delError, isSuccess])




    return (
        <>
            <div className='transition duration-300 ease-in relative  overFlow-hidden z-0 lg:block hover:scale-110 hover:z-50'>
                <div className='grid grid-rows-2 rounded-md hover:border'>
                    <div>
                        <Link to={`/movie/${movie?._id}`} >
                            <img src={movie?.image ? `${movie.image}` : '/images/user2.png'}
                                alt={movie?.name} className='lg:w-full w-full lg:h-48 h-24 object-cover rounded-tr-lg rounded-tl-lg bg-dry'
                            />
                        </Link>
                    </div>
                    <div className='bg-[#141414] px-4 lg:pt-4 pt-2 rounded-br-lg rounded-bl-lg '>
                        <div className='grid lg:grid-cols-[80%_10%_10%] grid-cols-[70%_20%_10%] lg:pr-0 pr-1'>
                            <div>
                                <p class="text-white font-bold lg:text-lg lg:line-clamp-3 line-clamp-2">{movie?.name}</p>
                            </div>
                            <div>
                                <motion.button
                                    whileHover={{ scale: 1.3 }} whileTap={{ scale: 0.7 }}
                                    onClick={() => LikeMovie(movie, dispatch, userInfo)}
                                    disabled={isLiked(movie) || likeLoading}
                                    className={` bg-white hover:scale-125 transitions  px-2 py-2 rounded-full text-sm bg-opacity-10 
                                ${isLiked(movie) ? "text-[#FF0000]" : "text-white"
                                        }`}
                                >
                                    <FaHeart size={10} />
                                </motion.button>
                            </div>
                            <div className='lg:pl-2 pl-1'>
                                <button className='bg-white hover:scale-125 transitions text-white px-2 py-2 rounded-full text-sm bg-opacity-10'
                                    onClick={(() => navigate(`/movie/${movie?._id}`))}
                                >
                                    <FaPlay size={10} className='' />
                                </button>
                            </div>
                        </div>
                        <div className='flex items-center gap-4 font-medium text-dryGray pt-2 lg:flex hidden'>
                            <FlexMovieItem movie={movie && movie} />
                        </div>
                        <div className='lg:pt-4'>
                            <p className='text-gray-600 text-sm lg:line-clamp-3 line-clamp-2'>{movie?.desc}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MovieCard