import React from 'react'
import { createContext } from 'react';
import { useMemo } from 'react';
import { useState } from 'react'

export const SidebarContext = createContext();

function DrawerContext({ children }) {
  const [mobileDrawer, setMobileDrawer] = useState(false);
  const [progress, setProgress] = useState(0);
  const toggleDrawer = () => setMobileDrawer(!mobileDrawer);
  const value = useMemo(() => ({ mobileDrawer, toggleDrawer, setProgress }), [mobileDrawer, progress])
  return (
    <SidebarContext.Provider value={value}>{children}</SidebarContext.Provider>
  )
}

export default DrawerContext