import React, { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import Banner from '../components/home/Banner'
import BollywoodMovies from '../components/home/BollywoodMovies'
import HollywoodMovies from '../components/home/HollywoodMovies'
import PopularMovies from '../components/home/PopularMovies'
import PremiumMovies from '../components/home/PremiumMovies'
import Promos from '../components/home/Promos'
import TopRated from '../components/home/TopRated'
import Layout from '../layout/Layout'
import { moviesBollywoodAction, moviesHollywoodAction, moviesListAction, moviesListLoginAction, moviesLoginRandomAction, moviesPremiumAction, moviesRandomAction, moviesTopRatedAction } from '../Redux/Actions/MovieAction'
import SEO from '../SEO/Seo'
import { useState } from 'react'
import { onValue, ref } from '@firebase/database'
import { SignInWithGoogle, db } from '../firebase/config'
import ContinueWatching from '../components/home/ContinueWatching'
import axios from 'axios'
import LoginModal from '../components/modals/LoginModal'
import SubscribeCard from '../components/home/SubscribeCard'
import SubscribeVid from '../components/home/SubscribeVid'
import Adds from '../components/ads/Ads'

function Homescreen() {
  const dispatch = useDispatch()
  const [modalOpen, setModalOpen] = useState(false)
  // console.log(db)

  const [continueWatchingList, setContinueWatchingList] = useState([]);
  const [continueWatchingMovies, setContinueWatchingMovies] = useState([]);
  const [continueWatchingLoading, setContinueWatchingLoading] = useState(true);

  const { isLoading: randomLoading, isError: randomError, movies: randomMovies } = useSelector((state) => state.getRandomMovies);

  const { isLoading: randomLoginLoading, isError: randomLoginError, movies: randomLoginMovies } = useSelector((state) => state.getRandomMoviesLogin);

  const { isLoading: bollywoodLoading, isError: bollywoodError, movies: bollywoodMovies } = useSelector((state) => state.getBollywoodMovies);

  const { isLoading: hollywoodLoading, isError: hollywoodError, movies: hollywoodMovies } = useSelector((state) => state.getHollywoodMovies);

  const { isLoading: premiumLoading, isError: premiumError, movies: premiumMovies } = useSelector((state) => state.getPremiumMovies);

  const { isLoading: topLoading, isError: topError, movies: topMovies } = useSelector((state) => state.getTopRatedMovie);

  const { isLoading, isError, movies } = useSelector((state) => state.movieList);
  // const { isLoading: continueLoading, isError: continueError, movies: continueMovies } = useSelector((state) => state.continueWatching);


  const { isLoading: isLoad, isError: isErr, movies: loginMovie } = useSelector((state) => state.movieLoginList)

  const { userInfo } = useSelector((state) => state.userLogin);

  useEffect(() => {
    dispatch(moviesListLoginAction({}));
    dispatch(moviesLoginRandomAction());

    if (userInfo) {
      const dbRef = ref(db, `playback/${userInfo?._id}`);
      onValue(dbRef, (snapshot) => {
        const data = snapshot.val();
        if (data) {
          //  data is an object: "633333": {isWatched: true, currentPos: 0.0000000000000001, isPaused: true} where 633333 is the movie id so we need to get movieIds where isWatched is false and get all movieids
          const continueWatch = Object.keys(data).filter((key) => !data[key].isWatched);
          setContinueWatchingList(continueWatch);
        }
      });
      dispatch(moviesListLoginAction({}));
      dispatch(moviesLoginRandomAction());
    } else {
      dispatch(moviesListAction({}));
      dispatch(moviesRandomAction());
    }
    dispatch(moviesTopRatedAction());
    dispatch(moviesBollywoodAction());
    dispatch(moviesHollywoodAction());
    dispatch(moviesPremiumAction());
    if (isError || randomError || topError || bollywoodError || hollywoodError || premiumError || randomLoginError || isErr) {
      console.log(isError, randomError, topError, bollywoodError, hollywoodError, premiumError, randomLoginError, isErr)
    }
  }, [dispatch, isError, randomError, topError, bollywoodError, hollywoodError, premiumError, randomLoginError, userInfo, isErr])

  useEffect(() => {
    if (userInfo && continueWatchingList.length > 0) {
      async function getContinueWatchingMovies() {
        try {
          // if continueWatchingList contains "undefined" then it will throw error so we need to filter it out
          const filteredList = continueWatchingList.filter((id) => id !== "undefined");
          const res = await axios.post('https://plixx-72d54.web.app/api/loginMovies/continueWatching', { movieIds: filteredList }, {
            headers: {
              'Authorization': `Bearer ${userInfo?.token}`
            }
          })
          setContinueWatchingMovies(res.data);
          setContinueWatchingLoading(false);
        } catch (error) {
          console.log(error);
        }
      }


      getContinueWatchingMovies();
    }
  }, [userInfo, continueWatchingList])

  useEffect((e) => {
    if (!userInfo) {
      setTimeout(() => {
        setModalOpen(!modalOpen);
      }, 3000)
    }
  }, [userInfo])

  


  return (
    <>
      <LoginModal modalOpen={modalOpen} setModalOpen={setModalOpen} />
      <SEO title="Plixx | Watch Movies Online Free"
        description="Plixx is a movie streaming platform where you can watch movies online for free. Watch movies in HD quality without registration at 123movies, 123moviesfree, 123movies free, Gomovies 123movies Download free"
        name="Plixx"
        type="website"
        keywords="Plixx, Plixx Home, Plixx Movies, Plixx Movies Online, Plixx Movies Online Free, Plixx Movies Online Free Watch, Plixx Movies Online Free Watch HD, Plixx, plixx.co.in"

        
      />
    
      <Layout>
        <div className='mx-auto min-h-screen px-2 mb-6 '>
          <SubscribeCard/>
          <Banner movies={userInfo ? loginMovie : movies} isLoading={userInfo ? isLoad : isLoading} />
          <SubscribeVid/>
          {userInfo && continueWatchingList.length > 0 && <ContinueWatching movies={continueWatchingMovies} isLoading={continueWatchingLoading} />}
          <PopularMovies movies={userInfo ? randomLoginMovies : randomMovies} isLoading={userInfo ? randomLoginLoading : randomLoading} />
          <BollywoodMovies movies={bollywoodMovies} isLoading={bollywoodLoading} />
          
          <Adds/>
         
          <Promos />
          <HollywoodMovies movies={hollywoodMovies} isLoading={hollywoodLoading} />
          <PremiumMovies movies={premiumMovies} isLoading={premiumLoading} />
          {/* <Ads /> */}
          <TopRated movies={topMovies} isLoading={topLoading} />
        </div>
      </Layout>

    </>
  )
}

export default Homescreen