import React from 'react';
import Layout from './../layout/Layout';
import Head from '../components/Head';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

function SubscriptionPage() {

  const {userInfo} = useSelector((state) => state.userLogin)

  const navigate = useNavigate();

  const subData = [
    {
      id: 1,
      name: 'Basic',
      plan: 'Access to all premium features for 30 Days',
      price: '₹ 99',
      amount: 99,
      priceDuration: '/Month',
      benefits: [
        'Ads Free Experience',
        'Get Access To More Content',
        'High Quality Videos',
        'Refer and Earn'
      ],
    },
    {
      id: 1,
      name: 'Premium',
      plan: 'Access to all premium features for 30 Days',
      price: '₹ 199',
      amount: 199,
      priceDuration: '/3 Month',
      benefits: [
        'Ads Free Experience',
        'Get Access To More Content',
        'High Quality Videos',
        'Refer and Earn'
      ],
    },
    {
      id: 1,
      name: 'Premium',
      plan: 'Access to all premium features for 30 Days',
      price: '₹ 499',
      amount: 499,
      priceDuration: '/Year',
      benefits: [
        'Ads Free Experience',
        'Get Access To More Content',
        'High Quality Videos',
        'Refer and Earn'
      ],
    },
  ]

  return (
    <Layout>
      <div className='min-height-screen container mx-auto px-2 my-6'>
        <h1 className='lg:text-5xl text-3xl font-bold text-center px-4'>Premium plans for teams of all sizes</h1><br />
        <p className='text-center text-gray-500 lg:text-xl text-lg px-4'>Choose an offordable plan that's packed with the best features for engaging</p>
        <p className='text-center text-gray-500 lg:text-xl text-lg lg:mt-3 px-4'>your audience, creating customer loyalty, and driving sales.</p>
        <div class="grid lg:grid-cols-3 px-6 md:px-12 xl:px-16 lg:py-16 py-8 gap-16" >
          {
            subData.map((item, index) => (

              <div class="rounded-3xl shadow-lg bg-main border-2 border-gray-800 hover:border-subMain h-full p-6 hover:scale-90 ">
                <p class="mb-4 text-white">
                  <h1 className='font-bold text-2xl'>{item.name}</h1>
                  <h3 className='mt-2 text-gray-500 '>{item.plan}</h3>
                </p>
                {/* <button>
                  Popular
                </button> */}
                <h3 class="text-5xl mb-6 text-white">
                  <strong>{item.price}</strong>
                  <small class="text-gray-500 text-sm">{item.priceDuration}</small>
                </h3><br />
                <button
                onClick={(()=>userInfo ?navigate('/payments?price='+item.amount):navigate('/login'))}
                  whileHover={{ scale: 1.2 }}
                  whileTap={{ scale: 0.9 }}
                  transition={{ type: "spring", stiffness: 400, damping: 17 }}
                  type="button"
                  class="inline-block border-subMain px-6 py-2.5 mb-4 bg-subMain text-black font-bold  font-medium leading-tight uppercase rounded shadow-md hover:bg-subMain hover:shadow-lg  w-full">
                  <p className='font-bold'>Buy</p>
                </button>
                {
                  item.benefits.map((items)=>(
                <div class="px-2 pt-4">
                  <ol class="list-inside ">
                    <li class="mb-4 flex items-center text-white">
                      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check"
                        class="text-green-600 w-4 h-4 mr-2" role="img" xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512">
                        <path fill="currentColor"
                          d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z">
                        </path>
                      </svg>{items}
                    </li>
                  </ol>
                </div>
                ))
              }
              </div>
            ))}
        </div>
      </div>
    </Layout>
  );
}

export default SubscriptionPage;
