import React from 'react'
import Footer from './footer/Footer'
import MobileFooter from './footer/MobileFooter'
import Navbar from './navbar/Navbar'
import { Link, useNavigate } from 'react-router-dom'
import { FiSearch } from 'react-icons/fi'
import { useState } from 'react'
import { HiMenuAlt3 } from 'react-icons/hi'
import SideDrawer from '../components/Drawer/SideDrawer'

function MovieLayout({ children }) {
    const [search, setSearch] = useState("")
    const navigate = useNavigate();
    const onSearch = (e) => {
        e.preventDefault();
        if (search.trim()) {
            navigate(`${search}`)
            setSearch(search)
        }
    }

    const [isOpen, setIsOpen] = useState(false)
    const handleDrawer = () => {
        setIsOpen(!isOpen)
    }


    const active = "bg-dry text-subMain"
    const hoverr = "hover:bg-dry"
    const inActive = "rounded  font-medium text-sm transitions flex gap-3 items-center sm:px-8 px-4 py-4 "
    const Hoverr = ({ isActive }) =>
        isActive ? `${active} ${inActive}` : `${inActive} ${hoverr}`;
    const hover = "hover:text-subMain transitions text-white";
    const Hover = ({ isActive }) => (isActive ? 'text-subMain' : hover)


    return (
        <>
           
            <div className='bg-main text-white  '>
                <div className='bg-main shadow-md sticky top-0 z-20 block lg:hidden'>
                    <div className='container mx-auto py-6 px-2 lg:grid gap-10 grid-cols-7 '>
                        {/*Logo*/}
                        <div class="sm:hidden flex bg-dry">

                            <input type="search" placeholder=" Search" value={search} onChange={(e) => setSearch(e.target.value)}
                                class="w-full appearance-none border-b-20 bg-transparent py-2 px-3 texy-white  focus:outline-none" />
                            <button onClick={onSearch} className="pr-2" >
                                <FiSearch size={25} />
                            </button>
                            <button class="py-3 ml-3" onClick={handleDrawer} >
                                <HiMenuAlt3 size={25} />
                            </button>
                        </div>
                    </div>
                </div>
                <div className='hidden lg:block'>
                    <Navbar />
                </div>
                {children}
                <Footer />
                {/*Mobile Footer */}
                <MobileFooter />
            </div>
            {/* drawer */}
            {
                isOpen && (
                    <SideDrawer handleDrawer={handleDrawer} Hoverr={Hoverr} />
                )
            }


        </>
    )
}

export default MovieLayout