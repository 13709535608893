import React, { useState, useEffect } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { FaFacebook, FaHeart, FaListAlt, FaMedium, FaTelegram, FaUser, FaYoutube } from 'react-icons/fa'
import { CgProfile, CgUser } from 'react-icons/cg'
import { useDispatch, useSelector } from 'react-redux';
import { IoIosMenu } from 'react-icons/io';
import { HiMenuAlt3, HiOutlineUserGroup, HiViewGridAdd } from 'react-icons/hi';
import { FiSearch, FiSettings } from 'react-icons/fi';
import { MdCancel, MdOutlineMovieFilter } from 'react-icons/md';
import { BiMoviePlay, BiPhoneCall } from 'react-icons/bi';
import { BsCollectionPlay, BsFillGridFill } from 'react-icons/bs';
import { RiLockPasswordLine, RiLogoutCircleLine, RiMovie2Fill, RiMovieLine } from 'react-icons/ri';
import { AiOutlineHeart } from 'react-icons/ai';
import Dropdown from '../../components/Dropdown';
import { logoutAction } from '../../Redux/Actions/userAction';
import { toast } from 'react-hot-toast';
import { motion } from "framer-motion";
import SideDrawer from '../../components/Drawer/SideDrawer';

function Navbar() {
    const [search, setSearch] = useState("")
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state) => state.userLogin);
    const { likedMovies } = useSelector((state) => state.userFavMovies)

    //drawer

    const [isOpen, setIsOpen] = useState(false)
    const handleDrawer = () => {
        setIsOpen(!isOpen)
    }

   

    //search

    const [isSearch, setIsSearch] = useState(false)
    const onSearch = () => {
        setIsSearch(!isSearch)
        navigate('/movies')
    }

    const handleSearch = (e) => {
        e.preventDefault();
        if (search.trim()) {
            navigate(`/movies/${search}`)
            setSearch(search)
        }
        else {
            navigate(`/movies`)
        }
    }

    const [isScrolled, setIsScrolled] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScrolled(true)

            }
            else {
                setIsScrolled(false)
            }
        }
        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    const SideLink =
        userInfo?.isAdmin ? ([
            {
                name: "Movies",
                link: "/movies",
                icon: BsCollectionPlay
            },
            {
                name: "About Us",
                link: "/about-us",
                icon: HiOutlineUserGroup
            },
            {
                name: "Contact Us",
                link: "/contact-us",
                icon: BiPhoneCall
            },
            {
                name: "Dashboard",
                link: "/dashboard",
                icon: BsFillGridFill
            },
            {
                name: "Movies List",
                link: "/movie-list",
                icon: FaListAlt
            },
            {
                name: "Add Movie",
                link: "/addMovie",
                icon: RiMovie2Fill
            },
            {
                name: "Categories",
                link: "/categories",
                icon: HiViewGridAdd
            },
            {
                name: "Users",
                link: "/users",
                icon: FaUser
            },
            {
                name: "Profile",
                link: "/profile",
                icon: FiSettings
            },
            {
                name: "Favourite Movies",
                link: "/favourite",
                icon: FaHeart
            }
        ]
        ) : userInfo ? (
            [
                {
                    name: "Movies",
                    link: "/movies",
                    icon: BsCollectionPlay
                },
                {
                    name: "Favourite Movies",
                    link: "/favourite",
                    icon: AiOutlineHeart
                },
                {
                    name: "About Us",
                    link: "/about-us",
                    icon: HiOutlineUserGroup
                },
                {
                    name: "Contact Us",
                    link: "/contact-us",
                    icon: BiPhoneCall
                },
                {
                    name: "Movies List",
                    link: '/movie-list',
                    icon: BiMoviePlay
                },
                {
                    name: "PSE",
                    link: '/pse',
                    icon: MdOutlineMovieFilter
                },
                {
                    name: `Hi, ${userInfo?.name}`,
                    link: "/profile",
                    icon: CgProfile
                },

            ]
        )
            : (
                [
                    {
                        name: "Movies",
                        link: "/movies",
                        icon: BsCollectionPlay
                    },
                    {
                        name: "About Us",
                        link: "/about-us",
                        icon: HiOutlineUserGroup
                    },
                    {
                        name: "Contact Us",
                        link: "/contact-us",
                        icon: BiPhoneCall
                    },
                    {
                        name: "Login",
                        link: "/login",
                        icon: CgProfile
                    },
                ]
            )
    const LinkDatas = [
        {
            icon: FaFacebook,
            link: ""
        },
        {
            icon: FaMedium,
            link: ""
        },
        {
            icon: FaTelegram,
            link: ""
        },
        {
            icon: FaYoutube,
            link: ""
        }

    ]

    const active = "bg-dry text-subMain"
    const hoverr = "hover:bg-dry"
    const inActive = "rounded  font-medium text-sm transitions flex gap-3 items-center sm:px-8 px-4 py-4 "
    const Hoverr = ({ isActive }) =>
        isActive ? `${active} ${inActive}` : `${inActive} ${hoverr}`;
    const hover = "hover:text-subMain transitions text-white";
    const Hover = ({ isActive }) => (isActive ? 'text-subMain' : hover)
    return (
        <div className={`${isScrolled && 'bg-main'} bg-opacity-70 shadow-md sticky top-0 z-20`}>
            <div className='container py-3 px-2 lg:grid gap-8 grid-cols-7 '>
                {/*Logo*/}
                <div className='flex justify-between' >
                    <Link to="/">
                        <img src="../../images/updatedLogo3.png" alt='Plixx' className='w-9/12 md:w-9/12 lg:w-full h-12 object-contain sm:object-left' />
                    </Link>
                    <div class="sm:hidden flex ">
                        <button onClick={onSearch} >
                            <FiSearch size={25} />
                        </button>

                        <button class="py-3 ml-3" onClick={handleDrawer} >
                            <HiMenuAlt3 size={25} />
                        </button>
                    </div>
                </div>

                <div className='col-span-4 font-medium text-sm hidden xl:gap-14 2xl:gap-19 justify-between lg:flex xl:justify-start items-center '>
                    <NavLink to="/movies" className={Hover}>
                        Movies
                    </NavLink>
                    <NavLink to="/pse" className={Hover}>
                        PSE
                    </NavLink>
                    <NavLink to="/porn" className={Hover}>
                        Porn
                    </NavLink>
                    <NavLink to="/about-us" className={Hover}>
                        About Us
                    </NavLink>
                    <NavLink to="/contact-us" className={Hover}>
                        Contact Us
                    </NavLink>
                    {/* <NavLink to="/favourite" className={Hover}>
                        My List
                    </NavLink> */}
                    {userInfo?.hasPaid === false && (
                        <>

                            <NavLink to="/premium" className={Hover}>
                                Premium
                            </NavLink>
                        </>
                    )
                    }
                </div>
                <div className='col-span-2 font-medium text-sm hidden xl:gap-14 2xl:gap-19 justify-between lg:flex xl:justify-end pr-2 items-center'>
                    <form onSubmit={handleSearch} className='w-full text-sm bg-dry flex justify-between items-center rounded gap-4'>
                        <input type="text" placeholder=" Search" value={search} onChange={(e) => setSearch(e.target.value)}
                            className="w-full h-10 appearance-none border-white bg-dry py-2 px-3 text-white focus:outline-none"
                        />
                        <button type='submit' className='flex flex-col justify-center items-center rounded text-white pr-2'>
                            <FiSearch size={22} />
                        </button>
                    </form>
                    <div>
                        {
                            userInfo ? (
                                <Dropdown />
                            ) : (
                                <button onClick={(() => navigate('/login'))} className='bg-main transitions hover:bg-dry border border-white text-white py-2 px-6 rounded w-full h-10 sm:w-auto'>
                                    Login
                                </button>

                            )
                        }
                    </div>

                </div>

            </div>
            {/* drawer */}
            {
                isOpen && (
                  <SideDrawer handleDrawer={handleDrawer} Hoverr={Hoverr}/>
                )
            }
        </div>
    )
}

export default Navbar