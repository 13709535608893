import React from 'react'
import {FaEye, FaRegCalendarAlt} from 'react-icons/fa'
import {BiTime} from 'react-icons/bi'


function FlexPseItem({movie}) {
  return (
    <>
    <div className='flex items-center gap-2 '>
        <span className='text-sm font-medium'>{movie.like}</span>
    </div>
    <div className='flex items-center gap-2 '>
        <FaEye size={60} className="text-subMain w-3 h-3 " />
        <span className='text-sm font-medium'>{movie.views}</span>
    </div>
    <div className='flex items-center gap-2 '>
        <BiTime className="text-subMain w-3 h-3" />
        <span className='text-sm font-medium'>{movie.duration}</span>
    </div>
    </>

  )
}

export default FlexPseItem