import {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

function Completion(props) {
  const [ messageBody, setMessageBody ] = useState('');
  const { stripePromise } = props;

  const { userInfo } = useSelector((state) => state.userLogin)


  useEffect(() => {
    if (!stripePromise) return;

    stripePromise.then(async (stripe) => {
      const url = new URL(window.location);
      console.log('url', url);
      const clientSecret = url.searchParams.get('payment_intent_client_secret');
      const { error, paymentIntent } = await stripe.retrievePaymentIntent(clientSecret);
      console.log('paymentIntent', paymentIntent);

      if (paymentIntent.status === 'succeeded') {
        fetch("https://plixx-72d54.web.app/api/payment/webhook", {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${userInfo?.token}`,
          },
          body: JSON.stringify({
            type: 'payment_intent.succeeded',
            data: {
              object: paymentIntent,
            },
          }),
      }).then((r) => r.json()).then((r) => console.log('r', r));
      }

      setMessageBody(error ? `> ${error.message}` : (
        <>&gt; Payment {paymentIntent.status}: <a href="/" target="_blank" rel="noreferrer">{paymentIntent.id}</a></>
      ));
    });
  }, [stripePromise]);

  return (
    <>
      <h1>Thank you!</h1>
      <a href="/">home</a>
      <div id="messages" role="alert" style={messageBody ? {display: 'block'} : {}}>{messageBody}</div>
    </>
  );
}

export default Completion;