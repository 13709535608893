import React, { useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Input, Inputlogin } from '../components/UsedInput'
import Layout from '../layout/Layout'
import { FiLogIn } from 'react-icons/fi'
import GoogleButton from 'react-google-button'
import { useDispatch, useSelector } from 'react-redux'
import { yupResolver } from '@hookform/resolvers/yup'
import { loginAction, registerAction } from '../Redux/Actions/userAction'
import { toast } from 'react-hot-toast'
import { LoginValidation, RegisterValidation } from '../components/Validation/UserValidation'
import { InlineError } from '../components/Notifications/Error'
import { useForm } from 'react-hook-form'
import HalfLayout from '../layout/HalfLayout'
import { SignInWithGoogle } from '../firebase/config'
import SEO from '../SEO/Seo'
import Footer from '../layout/footer/Footer'
import { useState } from 'react'

function Register() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [referralLink, setReferralLink] = useState(``)

    const { isLoading, isError, userInfo, isSuccess } = useSelector((state) => state.userRegister);

    //validate user
    const handleGoogleSignIn = () => {
        SignInWithGoogle(referralLink)
    }

    let googleInfo = JSON.parse(localStorage.getItem("userInfo"))
    console.log(googleInfo)

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(RegisterValidation)
    })

    //on Submit
    const onSubmit = (data) => {
        dispatch(registerAction(data))
    }
    // done

    useEffect(() => {

        const searchParams = new URLSearchParams(location.search);
        const refParam = searchParams.get('ref');
        if (refParam) {
            setReferralLink(refParam)
        }
        if (userInfo?.isAdmin || googleInfo?.isAdmin) {
            navigate("/dashboard");
        }
        else if (userInfo || googleInfo) {
            navigate("/profile");
        }
        if (isSuccess) {
            toast.success(`Welcome ${userInfo.name}`);
            dispatch({ type: "USER_REGISTER_RESET" })
        }
        if (isError) {
            toast.error(isError)
            dispatch({ type: "USER_REGISTER_RESET" })

        }
    }, [isSuccess, isError, userInfo, navigate, dispatch]);
    return (
        <>
            <SEO
                title="Register | Plixx"
                description="Register to Plixx"
                type="website"
                name="Register"
                keywords="Register, Plixx, Plixx Register, plixx.com, plixx.com/register, plixx.co.in, plixx.co.in/register"
            />
            <div className='container mx-auto bg-main'>
                <section class="w-full h-screen bg-center bg-cover bg-tnj-one opacity-30" />

                <div className='px-2 absolute top-0 z-30 '>
                    <div className='grid grid-cols-2 '>
                        <div>
                            <Link to="/">
                                <img
                                    src='../../images/updatedLogo3.png'
                                    alt='logo'
                                    className='lg:w-36 w-20 lg:h-20 h-12 object-contain'
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='container absolute top-20 lg:px-32  flex px-4 flex-col justify-center items-center opacity-80 mb-50'>
                    <form onSubmit={handleSubmit(onSubmit)} className='w-full 2xl:w-2/5 lg:gap-5 gap-4 lg:px-10 lg:py-6 px-4 py-2 md:w-3/5 flex flex-col justify-center items-center bg-dry'>
                        <img
                            src='../../images/updatedLogo3.png'
                            alt='logo'
                            className='w-full lg:h-16 h-12 object-contain'
                        />
                        <div className='w-full'>
                            <Inputlogin
                                label="Full Name"
                                placeholder="Enter your Full Name"
                                type="name"
                                name="name"
                                register={register("name")}
                                bg={true}
                            />
                            {
                                errors.name && <InlineError
                                    text={errors.name.message} />

                            }
                        </div>
                        <div className='w-full'>
                            <Inputlogin
                                label="Email"
                                placeholder="plixx@gmail.com"
                                type="email"
                                name="email"
                                register={register("email")}
                                bg={true}
                            />
                            {
                                errors.email && <InlineError
                                    text={errors.email.message} />

                            }
                        </div>
                        <div className='w-full'>
                            <Inputlogin
                                label="Password"
                                placeholder="**********"
                                type="password"
                                name="password"
                                register={register("password")}
                                bg={true}
                            />
                            {
                                errors.password && <InlineError
                                    text={errors.password.message} />

                            }
                        </div>
                        <div className='w-full'>
                            <Inputlogin
                                label="Referred By"
                                placeholder="Referred By"
                                type="text"
                                name="referredBy"
                                register={register("referredBy")}
                                bg={true}
                                value={referralLink}
                            />
                            {
                                errors.password && <InlineError
                                    text={errors.password.message} />

                            }
                        </div>
                        <button
                            type='submit'
                            disabled={isLoading}
                            className='bg-blueColor hover:bg-pinkColor font-bold text-lg transitions hover:bg-main flex flex-row justify-center items-center gap-3 text-black hover:text-white lg:p-3 p-2 rounded-lg w-full'>

                            {
                                isLoading ? "Loading..." : (
                                    <>
                                        <FiLogIn />
                                        <h1 className='font-bold'>Sign Up</h1>
                                    </>
                                )
                            }
                        </button>
                        <button
                            onClick={handleGoogleSignIn}
                            className='bg-white flex flex-row justify-center items-center gap-4 text-black lg:p-3 p-2 rounded-lg w-full'>
                            <img
                                src='/images/google.png'
                                alt='google'
                                className='w-6 h-6 object-contain'
                            />
                            Sign In with Google
                        </button>
                        <p className='text-center text-gray-400'>
                            Already have an account?{" "}
                            <Link to="/login" className='text-gray-400 font-semibold ml-2'>
                                Sign In
                            </Link>
                        </p>
                        <p className='text-center text-gray-400 text-sm -mt-4'>
                            By proceeding you confirm tha you are above 18 years of age and agree to the
                            <Link to="/privacyPolicy" className='text-gray-400 font-semibold ml-2 text-sm'>
                                Privacy Policy & Term of Use
                            </Link>
                        </p>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Register