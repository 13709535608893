import React from 'react'
import MainModal from './MainModal'
import { FaFacebook, FaPinterest, FaTelegram, FaTwitter, FaWhatsapp } from 'react-icons/fa'
import { EmailShareButton, FacebookShareButton, PinterestShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share'
import {MdEmail} from 'react-icons/md'


function ShareMovieModal({ modalOpen, setModalOpen, movie, referral }) {
    const shareData=[
        {
            icon: FaFacebook,
            shareButton: FacebookShareButton
        },
        {
            icon: FaTwitter,
            shareButton: TwitterShareButton
        },
        {
            icon: FaTelegram,
            shareButton: TelegramShareButton
        },
        {
            icon: FaWhatsapp,
            shareButton: WhatsappShareButton
        },
        {
            icon: FaPinterest,
            shareButton: PinterestShareButton
        },
        {
            icon: MdEmail,
            shareButton: EmailShareButton
        },

    ];

    let url;

    if(movie){  
     url = `${window.location.protocol}//${window.location.host}/movie/${movie?._id}`;
    }else{
    url = referral;
    }

    return (
        <MainModal modalOpen={modalOpen} setModalOpen={setModalOpen}>
            <div className='inline-block sm:w-4/5 border border-border md:w-3/5 lg:w-2/5 w-full rounded-md align-middle p-10 overflow-y-auto h-full bg-main text-white '>
                <h2 className='text-2xl'>Share <span className='text-xl font-bold'>
                    "{movie?.name || 'Plixx'}"
                </span></h2>
                <form className='flex flex-row justify-center items-center flex-wrap gap-6 mt-6'>
                    {
                        shareData.map((data,index)=>(
                            <data.shareButton key={index} url={url} quote="Plixx | Free Movies Site">
                                <div className='w-12 transitions hover:bg-subMain flex flex-col justify-center items-center text-lg h-12 bg-white rounded bg-opacity-30'>
                                    <data.icon />
                                </div>
                            </data.shareButton>
                        ))
                    }
                </form>
            </div>
        </MainModal>
    )
}

export default ShareMovieModal