import React, { useContext, useEffect } from 'react'
import Table from '../../components/Table'
import Sidebar from './Sidebar'
import { Movies } from '../../data/MoviesData'
import { useDispatch, useSelector } from 'react-redux';
import { deleteAllLikedMoviesAction, getFavouriteMoviesAction } from '../../Redux/Actions/userAction';
import { toast } from 'react-hot-toast';
import Loader from '../../components/Notifications/Loader'
import Empty from '../../components/Notifications/Empty';
import { DownloadVideo } from '../../context/Functionalities';
import FileSaver from 'file-saver';
import { SidebarContext } from '../../context/DrawerContext';
import SEO from '../../SEO/Seo';
import { TbPlayerTrackNext, TbPlayerTrackPrev } from 'react-icons/tb';
import { RiMovie2Line } from 'react-icons/ri';
import Movie from '../../components/Movie';
import FavMovie from '../../components/FavMovie';



function FavouriteMovie() {
  const dispatch = useDispatch();
  const { progress, setProgress } = useContext(SidebarContext)

  const {
    isLoading,
    isError,
    likedMovies
  } = useSelector((state) => state.userFavMovies)

  const {
    isLoading: delLoading,
    isError: delError,
    isSuccess
  } = useSelector((state) => state.userDeleteAllFavs)

  const deleteFavsHandler = () => {
    window.confirm("Are you sure you want to delete all your favourite movies?") &&
      dispatch(deleteAllLikedMoviesAction())
  }

  useEffect(() => {
    // dispatch(getFavouriteMoviesAction())
    if (isError || delError) {
      toast.error(isError || delError)
      dispatch({ type: isError ? "USER_GET_FAVOURITE_MOVIE_RESET" : "USER_DELETE_FAVOURITE_MOVIES_RESET" })
    }

  }, [dispatch, isError, delError,isSuccess])

  //download movie
  const downloadMovie = async (videoUrl, name) => {
    await DownloadVideo(videoUrl, setProgress).then((data) => {
        setProgress(0);
        FileSaver.saveAs(data, name);
    });
}

  return (
    <>
    <SEO
      title="Favourite Movies | Plixx"
      description="Favourite Movies | Plixx"
      name="Plixx"
      type="website"
      keywords='plixx.co.in, plixx, free bollywood movies, free hollywood movies, free movies, watch movies online, watch movies, watch movies free, download movies, download movies free, download movies online'
    />
    <Sidebar>
      <div className='flex flex-col gap-6'>
        <div className='flex justify-between items-center gap-2'>
          <h2 className='text-xl font-bold '>Favourite Movies</h2>
           {/* <hr className='text-subMain pb-4'/> */}
        </div>
       
        {
          isLoading ?
            <Loader /> :
            likedMovies?.length > 0 ?
              // <Table data={likedMovies} admin={false} downloadMovie={downloadMovie} progress={progress} /> : <Empty message="No favourite movie found"/>
              (
                <>
                    <div className='grid gap-2 lg:grid-cols-5 '>
                        {
                            likedMovies.map((movie, index) => (
                                <FavMovie key={index} movie={movie} />
                            ))
                        }
                    </div>
                    {/*Loading More*/}
                    {/* <div className='w-full flex flex-rows justify-center items-center gap-6 md:my-20 my-10 '>
                        <button onClick={prevPageHandler} disabled={page === 1} className={sameClass}>
                            <TbPlayerTrackPrev className='text-xl' />
                        </button>
                        <button onClick={nextPageHandler} disabled={page === pages} className={sameClass}>
                            <TbPlayerTrackNext className='text-xl' />
                        </button>
                    </div> */}
                </>


            ) : (
                <div className="w-full gap-6 flex flex-col justify-center items-center min-h-screen">
                    <div className='w-24 h-24 p-5 rounded-full mb-4 bg-dry text-subMain text-4xl flex flex-col justify-center items-center'>
                        <RiMovie2Line />
                    </div>
                    <p className='text-border text-sm'>
                        No movies found
                    </p>
                </div>
            )

        }

      </div>
    </Sidebar>
    </>
  )
}

export default FavouriteMovie