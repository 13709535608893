export const GET_CATEGORIES_REQUEST = 'GET_CATEGORIES_REQUEST'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_FAIL = 'GET_CATEGORIES_FAIL'
export const GET_CATEGORIES_RESET = 'GET_CATEGORIES_RESET'

// update
export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_FAIL = 'UPDATE_CATEGORY_FAIL'
export const UPDATE_CATEGORY_RESET = 'UPDATE_CATEGORY_RESET'

// delete
export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_FAIL = 'DELETE_CATEGORY_FAIL'
export const DELETE_CATEGORY_RESET = 'DELETE_CATEGORY_RESET'

// add
export const ADD_CATEGORY_REQUEST = 'ADD_CATEGORY_REQUEST'
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS'
export const ADD_CATEGORY_FAIL = 'ADD_CATEGORY_FAIL'
export const ADD_CATEGORY_RESET = 'ADD_CATEGORY_RESET'


