import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { referredUserAction } from '../Redux/Actions/userAction'

export const Stats = ({ referralCode }) => {
    const { referredUsers} = useSelector(state => state.referredUser)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(referredUserAction(referralCode))
    }, [dispatch])

    console.log(referredUsers)
    return (
        <div className="max-w-full mx-4 py-6 sm:mx-auto sm:px-6 lg:px-8">
            <div className="sm:flex sm:space-x-4">
                <div className="inline-block align-bottom bg-dry rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                    <div className="bg-dry p-5 border border-border">
                        <div className="sm:flex sm:items-start">
                            <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                <h3 className="text-sm leading-6 font-medium text-gray-400">Total Referrals</h3>
                                <p className="text-3xl font-bold text-white">0</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8">
                    <div className="bg-dry p-5 border border-border">
                        <div className="sm:flex sm:items-start">
                            <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                <h3 className="text-sm leading-6 font-medium text-gray-400">Amount Earned</h3>
                                <p className="text-3xl font-bold text-white">0 ₹</p>
                            </div>
                        </div>
                    </div>
                </div>
                <button className="inline-block align-bottom bg-dry border border-border rounded-lg text-left overflow-hidden shadow transform transition-all mb-4 w-full sm:w-1/3 sm:my-8 focus:outline-none">
                    <div className="bg-dry p-5">
                        <div className="sm:flex sm:items-start">
                            <div className="text-center sm:mt-0 sm:ml-2 sm:text-left">
                                <h3 className="flex  justify-center items-center leading-6 font-medium text-white text-3xl">Withdraw</h3>
                                {/* <p className="text-3xl font-bold text-black">24.57%</p> */}
                            </div>
                        </div>
                    </div>
                </button>
            </div>
        </div>
    )
}
