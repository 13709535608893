import React, { useContext, useEffect, useState } from "react";
import Layout from "../layout/Layout";
import { FiSearch } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Input } from "../components/UsedInput";
import axios from "axios";
import MovieCard from "../components/MoviesCard";
import PseCard from "../components/PseCards";
import Loader from "../components/Notifications/Loader";
import InfiniteScroll from "react-infinite-scroll-component";
import MobileAds from "../components/ads/MobileAds";
import DesktopAd from "../components/ads/DesktopAds";
import Adds from "../components/ads/Ads";

function PSEPage() {
  const [search, setSearch] = useState("");
  const [movieData, setMovieData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);
  const navigate = useNavigate();

  useEffect(() => {
    handleSubmit();
  }, [page, search]);

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
      setMovieData([]);
      setPage(1);
      setIsLoading(true);
      return;
    }

    const options = {
      method: "GET",
      url: "https://quality-porn.p.rapidapi.com/search",
      params: { query: search, page: page, timeout: "5000" },
      headers: {
        "X-RapidAPI-Key": "fc2d932218msh64f21ad4212ecd3p1af359jsn1c8c5abd9df9",
        "X-RapidAPI-Host": "quality-porn.p.rapidapi.com",
      },
    };

    try {
      const response = await axios.request(options);
      if (e) {
        setMovieData(response.data.data);
      }
      setMovieData((prevMovieData) => [...prevMovieData, ...response.data.data]);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Layout>

      <div className='min-height-screen container mx-auto px-2 my-1'>
        <div class="bg-subMain text-center py-4 lg:px-4">
          <div class="p-2 bg-[#85ede2] items-center text-indigo-100 leading-none lg:rounded-full flex lg:inline-flex" role="alert">
            <span class="flex rounded-full bg-[#13a192] uppercase px-2 py-1 text-xs font-bold mr-3">Use VPN</span>
            <span class="font-semibold mr-2 text-left text-black flex-auto">
              To get full access and better results
            </span>
            <svg class="fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" /></svg>
          </div>
        </div>
        <br />
        <div className="flex">
          {/* <div>
            <Adds/>
          </div> */}
          {/* <div>
            <DesktopAd/>
          </div> */}
          <div className="flex">
            <MobileAds/>
            <MobileAds/>
            <MobileAds/>
            <MobileAds/>
            <MobileAds/>
            <MobileAds/>
          </div>
        </div>
        <div className="bg-dry border text-dryGray rborder-gray-800 grid md:grid-cols-5 grid-cols-1 lg:grid-cols-1 gap-2 rounded py-4 lg:px-24 px-4">
          <form className="w-full text-sm bg-black flex justify-between items-center rounded gap-4">
            <input
              type="text"
              placeholder="Search"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              className="w-full h-11 appearance-none border-white bg-black py-2 px-3 text-white focus:outline-none"
            />
            <button
              // onClickCapture={onHandleClick}
              onClick={handleSubmit}
              className="bg-subMain text-black rounded px-4 py-4">
              <FiSearch />
            </button>
          </form>
        </div>
        {
          isLoading ? (
            <div className="w-full gap-6 flex flex-col justify-center items-center min-h-screen">
              <Loader />
            </div>
          ) : (
            <InfiniteScroll
              dataLength={movieData.length}
              next={() => setPage(page + 1)}
              hasMore={true}
              loader={<Loader />}
            >
              <div className="grid sm:mt-10 mt-6 xl:grid-cols-4 2xl:grid-cols-5 lg:grid-cols-3 grid-cols-1 gap-2">
                {movieData.map((site, index) => {
                  return site.links.length > 0 ? (
                    site.links.map((link) => (
                      <PseCard key={link.url} movie={link} />
                    ))
                  ) : (
                    <React.Fragment key={index}></React.Fragment>
                  );
                })}
              </div>
            </InfiniteScroll>
          )
        }
      </div>
    </Layout >

  );
}

export default PSEPage