import React, { useEffect, useState } from 'react'
import { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Movies } from '../../data/MoviesData'
import FlexMovieItem from '../FlexMovieItem'
import { Link, useNavigate } from 'react-router-dom'
import { FaHeart } from 'react-icons/fa'
import { RiMovie2Line } from 'react-icons/ri'
import Loader from '../Notifications/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { LikeMovie, MovieLiked } from '../../context/Functionalities'


function SubscribeCard() {
    const navigate = useNavigate();
    return (
        <div className='relative w-full mb-4 bg-main hidden lg:block'>
            <section class="w-full h-56 bg-center bg-cover bg-tnj-one opacity-10" />
            <div className='absolute top-2 grid grid-cols-[10%_40%_50%] py-2 px-2'>
                <div>
                </div>
                <div>
                <div className='absolute items-center justify-center z-50 top-10'>
                    <img src='/images/updatedLogo2.png' alt='about us'
                        className='w-34 xl:block hidden h-36 rounded-lg object-cover' />
                </div>
                <div className='opacity-50'>
                <img src='/images/sub9.jpg' alt='about us'
                        className='w-96 xl:block hidden h-52 rounded-lg object-fill ml-20' />
                </div>
                </div>
                <div className='pr-20' >
                    <p className='text-2xl text-gray-400'>Choose an offordable plan that's packed with the best features for engaging.</p><br/>
                    <button
                        onClick={(()=>navigate('/premium'))}
                        className='bg-gray-800 hover:bg-gray-900 transitions text-2xl text-white py-1 px-14 rounded w-full sm:w-auto'>
                        <h1 className='font-bold'>Subscribe</h1>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SubscribeCard