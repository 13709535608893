export const MOVIES_LIST_REQUEST = "MOVIES_LIST_REQUEST";
export const MOVIES_LIST_SUCCESS = "MOVIES_LIST_SUCCESS";
export const MOVIES_LIST_FAIL = "MOVIES_LIST_FAIL";
export const MOVIES_LIST_RESET = "MOVIES_LIST_RESET";

export const MOVIES_LIST_LOGIN_REQUEST = "MOVIE_LIST_LOGIN_REQUEST";
export const MOVIES_LIST_LOGIN_SUCCESS = "MOVIE_LIST_LOGIN_SUCCESS";
export const MOVIES_LIST_LOGIN_FAIL = "MOVIE_LIST_LOGIN_FAIL";
export const MOVIES_LIST_LOGIN_RESET = "MOVIE_LIST_LOGIN_RESET";

export const MOVIES_RANDOM_REQUEST = "MOVIES_RANDOM_REQUEST";
export const MOVIES_RANDOM_SUCCESS = "MOVIES_RANDOM_SUCCESS";
export const MOVIES_RANDOM_FAIL = "MOVIES_RANDOM_FAIL";
export const MOVIES_RANDOM_RESET = "MOVIES_RANDOM_RESET";

export const MOVIES_RANDOM_LOGIN_REQUEST = "MOVIES_RANDOM_LOGIN_REQUEST";
export const MOVIES_RANDOM_LOGIN_SUCCESS = "MOVIES_RANDOM_LOGIN_SUCCESS";
export const MOVIES_RANDOM_LOGIN_FAIL = "MOVIES_RANDOM_LOGIN_FAIL";
export const MOVIES_RANDOM_LOGIN_RESET = "MOVIES_RANDOM_LOGIN_RESET";

export const CONTINUE_WATCHING_REQUEST = "CONTINUE_WATCHING_REQUEST";
export const CONTINUE_WATCHING_SUCCESS = "CONTINUE_WATCHING_SUCCESS";
export const CONTINUE_WATCHING_FAIL = "CONTINUE_WATCHING_FAIL";
export const CONTINUE_WATCHING_RESET = "CONTINUE_WATCHING_RESET";


export const MOVIES_HOLLYWOOD_REQUEST = "MOVIES_HOLLYWOOD_REQUEST";
export const MOVIES_HOLLYWOOD_SUCCESS = "MOVIES_HOLLYWOOD_SUCCESS";
export const MOVIES_HOLLYWOOD_FAIL = "MOVIES_HOLLYWOOD_FAIL";
export const MOVIES_HOLLYWOOD_RESET = "MOVIES_HOLLYWOOD_RESET";

export const MOVIES_BOLLYWOOD_REQUEST = "MOVIES_BOLLYWOOD_REQUEST";
export const MOVIES_BOLLYWOOD_SUCCESS = "MOVIES_BOLLYWOOD_SUCCESS";
export const MOVIES_BOLLYWOOD_FAIL = "MOVIES_BOLLYWOOD_FAIL";
export const MOVIES_BOLLYWOOD_RESET = "MOVIES_BOLLYWOOD_RESET";

export const MOVIES_PREMIUM_MOVIES_REQUEST = "MOVIES_PREMIUM_MOVIES_REQUEST";
export const MOVIES_PREMIUM_MOVIES_SUCCESS = "MOVIES_PREMIUM_MOVIES_SUCCESS";
export const MOVIES_PREMIUM_MOVIES_FAIL = "MOVIES_PREMIUM_MOVIES_FAIL";
export const MOVIES_PREMIUM_MOVIES_RESET = "MOVIES_PREMIUM_MOVIES_RESET";

export const MOVIES_TOP_RATED_REQUEST = "MOVIES_TOP_RATED_REQUEST";
export const MOVIES_TOP_RATED_SUCCESS = "MOVIES_TOP_RATED_SUCCESS";
export const MOVIES_TOP_RATED_FAIL = "MOVIES_TOP_RATED_FAIL";
export const MOVIES_TOP_RATED_RESET = "MOVIES_TOP_RATED_RESET";

export const MOVIE_DETAILS_REQUEST = "MOVIE_DETAILS_REQUEST";
export const MOVIE_DETAILS_SUCCESS = "MOVIE_DETAILS_SUCCESS";
export const MOVIE_DETAILS_FAIL = "MOVIE_DETAILS_FAIL";
export const MOVIE_DETAILS_RESET = "MOVIE_DETAILS_RESET";

export const MOVIE_REVIEW_REQUEST = "MOVIE_REVIEW_REQUEST";
export const MOVIE_REVIEW_SUCCESS = "MOVIE_REVIEW_SUCCESS";
export const MOVIE_REVIEW_FAIL = "MOVIE_REVIEW_FAIL";
export const MOVIE_REVIEW_RESET = "MOVIE_REVIEW_RESET";

export const MOVIE_DELETE_REQUEST = "MOVIE_DELETE_REQUEST";
export const MOVIE_DELETE_SUCCESS = "MOVIE_DELETE_SUCCESS";
export const MOVIE_DELETE_FAIL = "MOVIE_DELETE_FAIL";
export const MOVIE_DELETE_RESET = "MOVIE_DELETE_RESET";

export const DELETE_ALL_MOVIES_REQUEST = "DELETE_ALL_MOVIES_REQUEST";
export const DELETE_ALL_MOVIES_SUCCESS = "DELETE_ALL_MOVIES_SUCCESS";
export const DELETE_ALL_MOVIES_FAIL = "DELETE_ALL_MOVIES_FAIL";
export const DELETE_ALL_MOVIES_RESET = "DELETE_ALL_MOVIES_RESET";

export const MOVIE_CREATE_REQUEST = "MOVIE_CREATE_REQUEST";
export const MOVIE_CREATE_SUCCESS = "MOVIE_CREATE_SUCCESS";
export const MOVIE_CREATE_FAIL = "MOVIE_CREATE_FAIL";
export const MOVIE_CREATE_RESET = "MOVIE_CREATE_RESET";

export const MOVIE_UPDATE_REQUEST = "MOVIE_UPDATE_REQUEST";
export const MOVIE_UPDATE_SUCCESS = "MOVIE_UPDATE_SUCCESS";
export const MOVIE_UPDATE_FAIL = "MOVIE_UPDATE_FAIL";
export const MOVIE_UPDATE_RESET = "MOVIE_UPDATE_RESET";

// casts
export const ADD_CASTS = "ADD_CASTS";
export const EDIT_CASTS = "EDIT_CASTS";
export const DELETE_CASTS = "DELETE_CASTS";
export const RESET_CASTS = "RESET_CASTS";

export const GET_VIDEO_BY_ID_REQUEST = 'GET_VIDEO_BY_ID_REQUEST'
export const GET_VIDEO_BY_ID_SUCCESS = 'GET_VIDEO_BY_ID_SUCCESS'
export const GET_VIDEO_BY_ID_FAIL = 'GET_VIDEO_BY_ID_FAIL'
export const GET_VIDEO_BY_ID_RESET = 'GET_VIDEO_BY_ID_RESET'







