import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { FaHeart } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { LikeMovie, MovieLiked } from '../context/Functionalities'


function Movie({ movie }) {
  const { isLoading } = useSelector((state) => state.userLikeMovie)
  const dispatch = useDispatch()
  const { userInfo } = useSelector((state) => state.userLogin)

  //if liked movie
  const isLiked = MovieLiked(movie)

  return (
    <>
      <div className='hover:border-b-4 hover:border-borderr transition duration-300 ease-in relative rounded-md overFlow-hidden hover:scale-90 z-0'
      >
        <Link to={`/movie/${movie?._id}`} >
          <img src={movie?.image ? `${movie.image}` : '/images/user2.png'}
            alt={movie?.name} className='w-full h-44 object-cover rounded hover:rounded-md'
          />
        </Link>
      </div>
    </>
  )
}

export default Movie