import React from 'react'
import { FiUser } from 'react-icons/fi'
import { Link } from 'react-router-dom'
function SubscribeVid() {
    return (
        <div className='my-2 bg-dry block lg:hidden'>
            <Link to='/premium'>
                <video
                    src="/sub.mp4"
                    autoPlay
                    muted
                    loop
                    className='w-full h-full lg:h-96 object-cover'
                />
            </Link>
        </div>
    )
}

export default SubscribeVid