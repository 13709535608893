import React from 'react'
import {FiUser} from 'react-icons/fi'
function Promos() {
  return (
    <div className='my-20 bg-dry'>
       <video
        src="/promo.mp4"
        autoPlay
        muted
        loop
        className='w-full h-full lg:h-96 object-cover'
      />
    </div>
  )
}

export default Promos