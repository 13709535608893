import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FiLogIn } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginAction } from '../../Redux/Actions/userAction';
import { InlineError } from '../Notifications/Error';
import { Input, InputLogin } from '../UsedInput';
import { toast } from 'react-hot-toast';
import { LoginValidation } from '../Validation/UserValidation';
import { SignInWithGoogle } from '../../firebase/config'
import { Icon } from '@iconify/react';


function LoginModal({ modalOpen, setModalOpen }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, isError, userInfo, isSuccess } = useSelector((state) => state.userLogin);

    // create a function to handle Sign in with Google and get the user data from local storage
    const handleGoogleSignIn = () => {
        SignInWithGoogle()
    }

    let googleInfo = JSON.parse(localStorage.getItem("userInfo"))

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(LoginValidation)
    })

    //on Submit
    const onSubmit = (data) => {
        dispatch(loginAction(data))
    }

    useEffect(() => {
        // if (userInfo?.isAdmin || googleInfo?.isAdmin) {
        //     navigate("/dashboard");
        // }
        // else if (userInfo || googleInfo) {
        //     navigate("/profile");
        // }
        if (isSuccess) {
            setModalOpen(false)
        }
        if (isError) {
            toast.error(isError)
            dispatch({ type: "USER_LOGIN_RESET" })
        }
    }, [isSuccess, isError, userInfo, navigate, dispatch, googleInfo])

    return (
        <>
            {modalOpen &&
                <div className="flex overflow-x-hidden overflow-y-auto h-modal md:h-full md:inset-0 z-50 lg:justify-end lg:mt-16 fixed bottom-0 lg:w-full w-full">
                    <div className="relative w-full max-w-md px-4 h-full md:h-auto">
                        <div className="bg-white w-full rounded-tr-lg rounded-tl-lg lg:rounded-lg shadow relative">
                            <div className="flex justify-end p-2"> 
                                <Icon icon="logos:google-icon" className='mt-2 text-xl'/>
                                <h1 className='flex text-gray-500 px-2 text-lg mt-1'>
                                    Sign in to Plixx with Google </h1>
                                <button type="button" className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white" onClick={() => setModalOpen(!modalOpen)}>
                                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                                </button>
                            </div>
                            <hr />
                            <div className='px-6 py-6'>
                                <button
                                    onClick={handleGoogleSignIn}
                                    className='border border-border hover:border-gray-300 hover:bg-gray-300 flex flex-row justify-center items-center gap-4 text-black p-2 rounded-lg w-full'>
                                    <img
                                        src='/images/google.png'
                                        alt='google'
                                        className='w-6 h-6 object-contain'
                                    />
                                    Sign In with Google
                                </button>
                                <p className='mt-3 ml-2'>
                                    <span className='text-gray-500'>
                                        To continue, you must agree to Plixx's Terms of Service and Privacy Policy. Google may share your data with Plixx.
                                    </span>
                                    <a href='/about-us' className='text-blue-500'>
                                        <span className='text-blue-500'>
                                            Learn more
                                        </span>
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default LoginModal