import React from 'react'
import Head from '../components/Head'
import Layout from './../layout/Layout'

function AboutUs() {
  return (
    <Layout>
      <div className='min-height-screen container mx-auto px-2 my-1'>
        <h1 className='lg:text-5xl text-3xl font-bold text-center px-4'>About Us</h1><br />
        <p className='text-center text-gray-500 lg:text-xl text-lg px-4'>Change to succeed · Risk-taking leads to success · Teamwork makes the dream work </p>
        <p className='text-center text-gray-500 lg:text-xl text-lg lg:mt-3 px-4'>· Don't fear failure · Think 'out of the box' · Envision greatness.</p>
        <div className=' xl:py-20 py-10 px-4 '>
          <div className='grid grid-flow-row xl:grid-cols-2 gap-4 cxl:gap-16 items-center'>
            <div>
              <h3 className='text-xl lg:text-3xl mb-4 font-semibold '>
                Welcome to our Plixx
              </h3>
              <div className='mt-3 text-sm leading-8 text-text'>
                <p>
                  Plixx offers free streaming for both movies and TV. You can watch Plixx through any internet-connected device that offers the Plixx app, including smart TVs, smartphones, and tablets. You can also watch Plixx on your computer using an internet browser. You can review the system requirements for web browser compatibility, and check our internet speed recommendations to achieve the best performance.
                  It is an ad-supported app, which means that you will have commercials. And like other app, you also get access to new movies and entertainment stuff.
                  It represents a collection of porn. With such a large library of content, you would expect the app to be pretty decent.It features endless scrolling, the ability to view multiple categories at once, and it even has Chromecast support so you can watch it on TV.
                </p>

              </div>
              {/* <div className='grid md:grid-cols-2 gap-6 mt-8'>
                <div className='p-8 bg-dry rounded-lg'>
                  <span className='text-3xl block font-extrabold'>
                    10k
                  </span>
                  <h4 className='text-lg font-semibold my-2'>
                    Listed Movies
                  </h4>
                  <p className='mb-0 text-text leading-7 text-sm'>
                    You can watch Plixx through any internet-connected device that offers the Netflix app.
                  </p>
                </div>
                <div className='p-8 bg-dry rounded-lg'>
                  <span className='text-3xl block font-extrabold'>
                    8k
                  </span>
                  <h4 className='text-lg font-semibold my-2'>
                    Lovely Users
                  </h4>
                  <p className='mb-0 text-text leading-7 text-sm'>
                    Completely free, without registration!                  </p>
                </div>
              </div> */}
            </div>
            <div className='mt-10 lg:mt-0'>
              {/* <img src='/images/about3.jpg' alt='about us' className='w-full xl:block hidden h-header rounded-lg object-cover' />
               */}
              <video
                autoPlay
                muted
                playsInline
                loop
              >
                <source
                  src='promo.mp4'
                  title='plixx'
                  type='video/mp4'
                />
              </video>
            </div>
          </div>
        </div>
      </div>
      <section class="mb-32 text-center">
        <h2 class="text-3xl font-bold mb-20 text-white">Why is it so great?</h2>
        <div class="grid lg:gap-x-12 lg:grid-cols-3 mx-11">
          <div class="mb-12 lg:mb-0">
            <div class="rounded-lg shadow-lg h-full block bg-[#141414]">
              <div class="flex justify-center">
                <div class="p-4 bg-subMain rounded-full shadow-lg inline-block -mt-8">
                  <svg class="w-8 h-8 text-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor"
                      d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z">
                    </path>
                  </svg>
                </div>
              </div>
              <div class="p-6">
                <h5 class="text-lg font-semibold mb-4 text-white">Support 24/7</h5>
                <p className='text-gray-600'>
                We offer dependable, on-demand support options including 24/7 technical support and remote support to get issues resolved anytime.
                </p>
              </div>
            </div>
          </div>

          <div class="mb-12 lg:mb-0">
            <div class="rounded-lg shadow-lg h-full block bg-[#141414]">
              <div class="flex justify-center">
                <div class="p-4 bg-subMain rounded-full shadow-lg inline-block -mt-8">
                  <svg class="w-8 h-8 text-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor"
                      d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM256.1 446.3l-.1-381 175.9 73.3c-3.3 151.4-82.1 261.1-175.8 307.7z">
                    </path>
                  </svg>
                </div>
              </div>
              <div class="p-6">
                <h5 class="text-lg font-semibold mb-4 text-white">Easy to watch</h5>
                <p className='text-gray-600'>
                Plixx is the large collection of fully searchable high-definition movies in the world.
                </p>
              </div>
            </div>
          </div>

          <div class="">
            <div class="rounded-lg shadow-lg h-full block bg-[#141414]">
              <div class="flex justify-center">
                <div class="p-4 bg-subMain rounded-full shadow-lg inline-block -mt-8">
                  <svg class="w-8 h-8 text-black" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path fill="currentColor"
                      d="M505.12019,19.09375c-1.18945-5.53125-6.65819-11-12.207-12.1875C460.716,0,435.507,0,410.40747,0,307.17523,0,245.26909,55.20312,199.05238,128H94.83772c-16.34763.01562-35.55658,11.875-42.88664,26.48438L2.51562,253.29688A28.4,28.4,0,0,0,0,264a24.00867,24.00867,0,0,0,24.00582,24H127.81618l-22.47457,22.46875c-11.36521,11.36133-12.99607,32.25781,0,45.25L156.24582,406.625c11.15623,11.1875,32.15619,13.15625,45.27726,0l22.47457-22.46875V488a24.00867,24.00867,0,0,0,24.00581,24,28.55934,28.55934,0,0,0,10.707-2.51562l98.72834-49.39063c14.62888-7.29687,26.50776-26.5,26.50776-42.85937V312.79688c72.59753-46.3125,128.03493-108.40626,128.03493-211.09376C512.07526,76.5,512.07526,51.29688,505.12019,19.09375ZM384.04033,168A40,40,0,1,1,424.05,128,40.02322,40.02322,0,0,1,384.04033,168Z" />
                  </svg>
                </div>
              </div>
              <div class="p-6">
                <h5 class="text-lg font-semibold mb-4 text-white">Extremely fast</h5>
                <p className='text-gray-600'>
               Review the system requirements for web browser compatibility, and check our internet speed recommendations to achieve the best performance.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="mb-32 text-gray-800 text-center lg:text-left">
        <div class="block rounded-lg shadow-lg bg-black">
          <div class="flex flex-wrap items-center">
            <div class="block w-full lg:flex grow-0 shrink-0 basis-auto lg:w-6/12 xl:w-4/12">
              <img
                src="/team.jpg"
                alt="Trendy Pants and Shoes"
                class="w-full rounded-t-lg lg:rounded-tr-none lg:rounded-bl-lg"
              />
            </div>
            <div class="grow-0 shrink-0 basis-auto w-full lg:w-6/12 xl:w-8/12">
              <div class="px-6 py-12 md:px-12">
                <h2 class="text-3xl font-bold mb-4 text-white display-5">Here we are....</h2>
                <p class="text-gray-500 mb-12">
                Change to succeed · Risk-taking leads to success · Teamwork makes the dream work · Don't fear failure · Think 'out of the box' · Envision greatness.
                </p>

                <div class="grid lg:gap-x-12 md:grid-cols-3">
                  <div class="mb-12 md:mb-0">
                    <h2 class="text-3xl font-bold text-white mb-4">200K</h2>
                    <h5 class="text-lg font-medium text-gray-500 mb-0">Happy customers</h5>
                  </div>

                  <div class="mb-12 md:mb-0">
                    <h2 class="text-3xl font-bold text-white mb-4">72%</h2>
                    <h5 class="text-lg font-medium text-gray-500 mb-0">Growth</h5>
                  </div>

                  <div class="">
                    <h2 class="text-3xl font-bold text-white mb-4">2.2M</h2>
                    <h5 class="text-lg font-medium text-gray-500 mb-0">Movies</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="mb-32 text-gray-800 text-center md:text-left">
        <div class="flex flex-wrap justify-center">
          <div class="grow-0 shrink-0 basis-auto w-full lg:w-10/12 px-3">
            <div class="grid md:grid-cols-2 gap-x-6 items-center">
              <div class="mb-6 md:mb-0">
                <h2 class="text-3xl font-bold">
                  Let's stay in touch
                  <br />
                  <span class="text-subMain">Join our social media</span>
                </h2>
              </div>

              <div class="mb-6 md:mb-0 md:ml-auto ">
                {/* <!-- Facebook --> */}
                <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light"
                  class="bg-[#1877f2] inline-block p-3 mb-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="w-4 h-4">
                    <path fill="currentColor"
                      d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z" />
                  </svg>
                </button>

                {/* <!-- Twitter --> */}
                <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light"
                  class="bg-[#1da1f2] ml-3 inline-block p-3 mb-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4">
                    <path fill="currentColor"
                      d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                  </svg>
                </button>

                {/* <!-- Google --> */}
                <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light"
                  class="bg-[#ea4335] ml-3 inline-block p-3 mb-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 488 512" class="w-4 h-4">
                    <path fill="currentColor"
                      d="M488 261.8C488 403.3 391.1 504 248 504 110.8 504 0 393.2 0 256S110.8 8 248 8c66.8 0 123 24.5 166.3 64.9l-67.5 64.9C258.5 52.6 94.3 116.6 94.3 256c0 86.5 69.1 156.6 153.7 156.6 98.2 0 135-70.4 140.8-106.9H248v-85.3h236.1c2.3 12.7 3.9 24.9 3.9 41.4z" />
                  </svg>
                </button>

                {/* <!-- Instagram --> */}
                <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light"
                  class="bg-[#c13584] ml-3 inline-block p-3 mb-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-4 h-4">
                    <path fill="currentColor"
                      d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z" />
                  </svg>
                </button>

                {/* <!-- Linkedin --> */}
                <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light"
                  class="bg-[#0077b5] ml-3 inline-block p-3 mb-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" class="w-4 h-4">
                    <path fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" />
                  </svg>
                </button>

                {/* <!-- Pinterest --> */}
                <button type="button" data-mdb-ripple="true" data-mdb-ripple-color="light"
                  class="bg-[#e60023] ml-3 inline-block p-3 mb-2 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" class="w-4 h-4">
                    <path fill="currentColor"
                      d="M204 6.5C101.4 6.5 0 74.9 0 185.6 0 256 39.6 296 63.6 296c9.9 0 15.6-27.6 15.6-35.4 0-9.3-23.7-29.1-23.7-67.8 0-80.4 61.2-137.4 140.4-137.4 68.1 0 118.5 38.7 118.5 109.8 0 53.1-21.3 152.7-90.3 152.7-24.9 0-46.2-18-46.2-43.8 0-37.8 26.4-74.4 26.4-113.4 0-66.2-93.9-54.2-93.9 25.8 0 16.8 2.1 35.4 9.6 50.7-13.8 59.4-42 147.9-42 209.1 0 18.9 2.7 37.5 4.5 56.4 3.4 3.8 1.7 3.4 6.9 1.5 50.4-69 48.6-82.5 71.4-172.8 12.3 23.4 44.1 36 69.3 36 106.2 0 153.9-103.5 153.9-196.8C384 71.3 298.2 6.5 204 6.5z" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutUs