import React from 'react'
import { AiOutlineHeart } from 'react-icons/ai'
import { BiMoviePlay, BiPhoneCall } from 'react-icons/bi'
import { BsCollectionPlay, BsFillGridFill } from 'react-icons/bs'
import { CgProfile } from 'react-icons/cg'
import { FaHeart, FaListAlt, FaUser } from 'react-icons/fa'
import { FiSettings } from 'react-icons/fi'
import { HiOutlineUserGroup, HiViewGridAdd } from 'react-icons/hi'
import { MdCancel, MdOutlineMovieFilter } from 'react-icons/md'
import { RiMovie2Fill } from 'react-icons/ri'
import { logoutAction } from '../../Redux/Actions/userAction'
import { toast } from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate } from 'react-router-dom'



function SideDrawer({handleDrawer, Hoverr}) {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { userInfo } = useSelector((state) => state.userLogin);


    const SideLink =
        userInfo?.isAdmin ? ([
            {
                name: "Movies",
                link: "/movies",
                icon: BsCollectionPlay
            },
            {
                name: "About Us",
                link: "/about-us",
                icon: HiOutlineUserGroup
            },
            {
                name: "Contact Us",
                link: "/contact-us",
                icon: BiPhoneCall
            },
            {
                name: "Dashboard",
                link: "/dashboard",
                icon: BsFillGridFill
            },
            {
                name: "Movies List",
                link: "/movie-list",
                icon: FaListAlt
            },
            {
                name: "Add Movie",
                link: "/addMovie",
                icon: RiMovie2Fill
            },
            {
                name: "Categories",
                link: "/categories",
                icon: HiViewGridAdd
            },
            {
                name: "Users",
                link: "/users",
                icon: FaUser
            },
            {
                name: "Profile",
                link: "/profile",
                icon: FiSettings
            },
            {
                name: "Favourite Movies",
                link: "/favourite",
                icon: FaHeart
            }
        ]
        ) : userInfo ? (
            [
                {
                    name: "Movies",
                    link: "/movies",
                    icon: BsCollectionPlay
                },
                {
                    name: "Favourite Movies",
                    link: "/favourite",
                    icon: AiOutlineHeart
                },
                {
                    name: "About Us",
                    link: "/about-us",
                    icon: HiOutlineUserGroup
                },
                {
                    name: "Contact Us",
                    link: "/contact-us",
                    icon: BiPhoneCall
                },
                {
                    name: "Movies List",
                    link: '/movie-list',
                    icon: BiMoviePlay
                },
                {
                    name: "PSE",
                    link: '/pse',
                    icon: MdOutlineMovieFilter
                },
                {
                    name: "Porn",
                    link: '/porn',
                    icon: MdOutlineMovieFilter
                },
                {
                    name: `Hi, ${userInfo?.name}`,
                    link: "/profile",
                    icon: CgProfile
                },

            ]
        )
            : (
                [
                    {
                        name: "Movies",
                        link: "/movies",
                        icon: BsCollectionPlay
                    },
                    {
                        name: "About Us",
                        link: "/about-us",
                        icon: HiOutlineUserGroup
                    },
                    {
                        name: "Contact Us",
                        link: "/contact-us",
                        icon: BiPhoneCall
                    },
                    {
                        name: "Login",
                        link: "/login",
                        icon: CgProfile
                    },
                ]
            )
   

    const logout = () => {
        dispatch(logoutAction())
        navigate("/login")
        toast.success("Logged Out Successfully")
    }

    return (
        <div
            className="sm:hidden fixed h-screen top-0 right-0 bottom-0 w-72 shadow-md bg-main  overflow-y-scroll">
            <div className="flex justify-between items-center px-4 py-3">
                <button onClick={handleDrawer} className="text-gray-500 hover:text-borderr focus:outline-none ">
                    <MdCancel size={30} />
                </button>
            </div>
            <div className='w-full overflow-y-scroll flex-grow max-height-full'>
                <div className=''>
                    {
                        SideLink.map((item, index) => (
                            <NavLink
                                key={index}
                                to={item.link}
                                className={Hoverr}>
                                <item.icon className='text-lg' /> {item.name}
                            </NavLink>
                        ))
                    }
                </div>
                {/* <div className='flex flex-row pl-4 gap-6 w-full'>
                    {
                        LinkDatas.map((link, index) => (
                            <a
                                key={index}
                                href={link.link}
                                target='_blank'
                                rel='noreferrer'
                                className='flex flex-col justify-center items-center w-6 h-6 transitions hover:bg-subMain text-lg rounded'>
                                <link.icon />
                            </a>
                        ))
                    }

                </div> */}
            </div >
            {
                userInfo?.isAdmin ? "" :
                    <div className='px-4 mb-8 mt-8'>
                        <p className='text-gray-500'>To get access to all the features, please subscribe to our premium plan.&nbsp; &nbsp;
                            <Link to='/premium' className='text-blue-600 underline'>Subscribe</Link>
                        </p>
                    </div>
            }

            {userInfo &&
                <button onClick={logout} className="w-64  rounded-full bg-subMain text-black block px-4 py-2 text-lg hover:bg-white hover:text-black mt-4 mb-20 mx-4" >
                    <p className='flex justify-center font-bold'>Log Out</p>
                </button>
            }
        </div>

    )
}

export default SideDrawer