import React, { useEffect, useRef } from 'react';

 export default function Adds() {
  const banner = useRef(null);

  const setBannerRef = (node) => {
    banner.current = node;
  };

  const atOptions = {
    key: '03fd3684f05cfc16441cbae85256b269',
    format: 'iframe',
    height: 50,
    width: 320,
    params: {},
  };

  useEffect(() => {
    if (!banner.current.firstChild) {
      const conf = document.createElement('script');
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = `https://www.highperformancedformats.com/${atOptions.key}/invoke.js`;
      conf.innerHTML = `atOptions = ${JSON.stringify(atOptions)}`;

      if (banner.current) {
        banner.current.appendChild(conf);
        banner.current.appendChild(script);
      }
    }
  }, []);

  return <div ref={setBannerRef} className='block lg:hidden' />;
}

 




