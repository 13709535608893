import React, { useContext } from 'react'
import { BsCollectionPlay } from 'react-icons/bs'
import { FaHeart } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import { FiHeart, FiLogIn, FiUserCheck } from 'react-icons/fi'
import { CgMenuBoxed } from 'react-icons/cg'
import { SidebarContext } from '../../context/DrawerContext'
import MenuuDrawer from '../../components/Drawer/MennuDrawer'
import { useSelector } from 'react-redux'
import { MdPayment } from 'react-icons/md'

function MobileFooter() {
    const { mobileDrawer, toggleDrawer } = useContext(SidebarContext)

    const { userInfo } = useSelector((state) => state.userLogin)

    const active = ""
    const inActive = "transitions text-2xl flex flex-col justify-center items-center hover:bg-white hover:text-main text-white rounded-md px-4 py-3"
    const Hover = ({ isActive }) => isActive ? `${active} ${inActive}` : inActive
    const { likedMovies } = useSelector((state) => state.userFavMovies)


    return (
        <>
            <div className='flex flex-col justify-between align-middle h-full bg-white rounded cursor-pointer overflow-y-scroll flex-grow w-full'>
                {/*Drawer */}
                <MenuuDrawer drawerOpen={mobileDrawer} toggleDrawer={toggleDrawer} />
            </div>
            <footer className='lg:hidden fixed z-30 bottom-0 w-full'>
                <div className='bg-dry rounded-md flex justify-between items-center w-full p-1'>
                    <NavLink to='/' className={Hover}>
                        <BsCollectionPlay />
                    </NavLink>
                    <NavLink to="/porn" className={Hover}>
                        <img src="/plixx_porn.png" alt="plixx_porn" className="w-8 h-7 " />
                    </NavLink>
                    {/* <button
                        onClick={toggleDrawer}
                        className={inActive}>
                        <CgMenuBoxed />
                    </button> */}
                    <NavLink to="/pse" className={Hover}>
                        <img src="/xxx.png" alt="plixx_xxx" className="w-8 h-10" />
                    </NavLink>
                    <NavLink to={userInfo ? userInfo.isAdmin ? '/dashboard' : '/profile' : '/login'} className={Hover}>
                        <FiUserCheck />
                    </NavLink>
                </div>
            </footer>
        </>
    )

}

export default MobileFooter