import React, { useEffect } from 'react'
import { toast } from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../components/Notifications/Loader';
import { movieDeleteAction, moviesListAction, moviesListLoginAction } from '../../../Redux/Actions/MovieAction';
import Sidebar from '../Sidebar'
import { RiMovie2Line } from 'react-icons/ri';
import EditDeleteMovies from '../../../components/EditDeleteMovies';


function MovieList({ downloadMovie, progress }) {
  const dispatch = useDispatch();
  const sameClass = 'text-white hover:text-black p-2 rounded font-semibold border-2 border-subMain hover:bg-subMain';

  //all movies
  const { isLoading, isError, movies, isSuccess, pages, page } = useSelector((state) => state.movieList);
  const { isLoading: isLoad, isError: isErr, movies: loginMovie, isSuccess: isSucc, pages: loginPages, page: loginPage } = useSelector((state) => state.movieLoginList)

  const { userInfo } = useSelector((state) => state.userLogin);

  let googleInfo = JSON.parse(localStorage.getItem("userInfo"))
  //delete movie
  const { isLoading: deleteLoading, isError: deleteError } = useSelector((state) => state.deleteMovie);

  //delete movie handler
  const deleteMovieHandler = (id) => {
    window.confirm("Are you sure you want to delete this movie?") &&
      dispatch(movieDeleteAction(id))
  }

  useEffect(() => {
    if (isError || deleteError || isErr) {
      toast.error(isError || deleteError || isErr)
    }
    if (googleInfo || userInfo) {
      dispatch(moviesListLoginAction({}))
    }
    else {
      dispatch(moviesListAction({}))
    }
  }, [dispatch, isError, deleteError, isErr, userInfo])



  return (
    <Sidebar>
      <div className='flex flex-col gap-6'>
        <div className='flex justify-between items-center gap-2'>
          <h1 className='text-xl font-bold'>Movies List</h1>
        </div>
        {
          isLoading || deleteLoading ? (
            <Loader />
          ) :
            (googleInfo || userInfo) ? loginMovie?.length > 0 ? (
              <>
                <div className='grid gap-2 lg:grid-cols-5 '>
                  {
                    loginMovie.map((movie, index) => (
                      <EditDeleteMovies key={index} movie={movie} onDeleteHandler={deleteMovieHandler} downloadMovie={downloadMovie} progress={progress} />
                    ))
                  }
                </div>
              </>
            ) : (
              <div className="w-full gap-6 flex flex-col justify-center items-center min-h-screen">
                <div className='w-24 h-24 p-5 rounded-full mb-4 bg-dry text-subMain text-4xl flex flex-col justify-center items-center'>
                  <RiMovie2Line />
                </div>
                <p className='text-border text-sm'>
                  No movies found
                </p>
              </div>
            )
              :
              movies?.length > 0 ? (
                <>
                  <div className='grid gap-2 lg:grid-cols-5 '>
                    {
                      movies.map((movie, index) => (
                        <EditDeleteMovies key={index} movie={movie} onDeleteHandler={deleteMovieHandler} downloadMovie={downloadMovie} progress={progress} />
                      ))
                    }
                  </div>
                </>
              ) : (
                <div className="w-full gap-6 flex flex-col justify-center items-center min-h-screen">
                  <div className='w-24 h-24 p-5 rounded-full mb-4 bg-dry text-subMain text-4xl flex flex-col justify-center items-center'>
                    <RiMovie2Line />
                  </div>
                  <p className='text-border text-sm'>
                    No movies found
                  </p>
                </div>
              )

        }

      </div>
    </Sidebar>
  )
}

export default MovieList