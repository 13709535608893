import { useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'
import HalfLayout from '../../layout/HalfLayout'
import { Link } from 'react-router-dom';
import Layout from '../../layout/Layout';
import { useSelector } from 'react-redux';
import axios from 'axios';

function Payment(props) {
  const { stripePromise } = props;
  const [clientSecret, setClientSecret] = useState('');

  const { userInfo } = useSelector((state) => state.userLogin)

  // get price from query param
  const price = new URLSearchParams(document.location.search).get('price');



  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    axios.post(
      "https://plixx-72d54.web.app/api/payment/create-payment-intent",
      { amount: price * 100 },
      {
        headers: {
          'Authorization': `Bearer ${userInfo?.token}`,
        },
      }
    )
    .then((res) => res.data)
    .then(({ clientSecret }) => setClientSecret(clientSecret))
    .catch((error) => console.error("Error creating payment intent:", error));
  }, []);
  


  return (
    <>
      <div className='lg:grid grid-cols-2 lg:block hidden'>
        <div className='py-56 bg-main '>
          <Link to="/">
            <img src="../../logoo.png" alt='Plixx' className='w-full h-80 object-contain' />
          </Link>
        </div>
        <div className='py-36'>
          <h1 className='flex items-center justify-center text-5xl font-bold mb-16'>Payment</h1>
          {clientSecret && stripePromise && (
            <Elements stripe={stripePromise} options={{ clientSecret, }}>
              <CheckoutForm />
            </Elements>
          )}
        </div>
      </div>
      <div className='lg:hidden block'>
        <Layout>
          <div className='grid bg-white'>
            <div className='py-11'>
              <h1 className='flex items-center justify-center text-5xl font-bold mb-8 text-black'>Payment</h1>
              {clientSecret && stripePromise && (
                <Elements stripe={stripePromise} options={{ clientSecret, }}>
                  <CheckoutForm />
                </Elements>
              )}
            </div>
          </div>
        </Layout>
      </div>
    </>
  );
}

export default Payment;