import React from 'react'
import { useState } from 'react';
import { BsFillGridFill } from 'react-icons/bs';
import { FaHeart, FaListAlt, FaUser } from 'react-icons/fa';
import { FiSettings } from 'react-icons/fi';
import { HiViewGridAdd } from 'react-icons/hi';
import { RiLockPasswordLine, RiMovie2Fill } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { logoutAction } from '../Redux/Actions/userAction';
import { toast } from 'react-hot-toast';
import { IoMdArrowDropdown } from 'react-icons/io';



function Dropdown() {
    const { userInfo } = useSelector((state) => state.userLogin);
    const [show, setShow] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const logout = () => {
        dispatch(logoutAction())
        navigate("/login")
        // toast.success("Logged Out Successfully")
    }

    const SideLink =
        userInfo?.isAdmin ? ([
            {
                name: "Dashboard",
                link: "/dashboard",
                icon: BsFillGridFill
            },
            {
                name: "Movies List",
                link: "/movie-list",
                icon: FaListAlt
            },
            {
                name: "Add Movie",
                link: "/addMovie",
                icon: RiMovie2Fill
            },
            {
                name: "Categories",
                link: "/categories",
                icon: HiViewGridAdd
            },
            {
                name: "Users",
                link: "/users",
                icon: FaUser
            },
            {
                name: "Profile",
                link: "/profile",
                icon: FiSettings
            },
            {
                name: "Favourite Movies",
                link: "/favourite",
                icon: FaHeart
            }
        ]
        ) : userInfo ? (
            [
                {
                    name: "Movies List",
                    link: "/movie-list",
                    icon: FaListAlt
                },
                {
                    name: "Profile",
                    link: "/profile",
                    icon: FiSettings
                },
                {
                    name: "Favourite Movies",
                    link: "/favourite",
                    icon: FaHeart
                }
            ]
        )
            : (
                []
            )
    const active = "bg-white  text-black "
    const hover = "hover:text-white hover:bg-main"
    const inActive = "text-gray-300 "
    const Hover = ({ isActive }) =>
        isActive ? `${active} ${inActive}` : `${inActive} ${hover}`;

    return (
        <>
            <div class="relative inline-block text-left">
                <div>
                    <button type="button" class="w-full text-sm font-semibold text-white"
                        onClick={(() => setShow(!show))} >
                      <span>{userInfo?.name}</span>
                    </button>
                </div>
                {
                    show &&
                    <div class="absolute right-0 z-10 mt-2 w-56 origin-top-right  rounded-md bg-gray-900 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                        <div class="py-1" role="none">
                            <p class="text-gray-300 block px-4 py-2  text-lg">Signed in as</p><p class="text-sm text-gray-300 block font-bold px-4 ">{userInfo?.email}</p>
                        </div>
                        {
                            SideLink.map((item, index) => (
                                <NavLink to={item.link} key={index} className='py-1'>
                                    <p className="text-gray-300 block px-4 py-2 text-lg hover:bg-white hover:text-black">{item.name}</p>
                                </NavLink>
                            ))
                        }
                        {userInfo &&
                            <button onClick={logout} className="w-full text-gray-300 block px-4 py-2 text-lg hover:bg-white hover:text-black" >
                                <p className='flex justify-start'>Log Out</p>
                            </button>
                        }
                    </div>
                }

            </div>
        </>

    )
}

export default Dropdown