import React, { useEffect, useState } from 'react'
import Uploader from '../../components/Uploader'
import Sidebar from './Sidebar'
import { Input } from '../../components/UsedInput'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ProfileUpdateValidation } from '../../components/Validation/UserValidation';
import { deleteProfileAction, logoutAction, updateProfileAction } from '../../Redux/Actions/userAction';
import { toast } from 'react-hot-toast';
import { InlineError } from '../../components/Notifications/Error';
import { ImagePreview } from '../../components/imagePreview';
import SEO from '../../SEO/Seo';
import { motion } from "framer-motion"
import { Stats } from '../../components/Stats';
import ShareMovieModal from '../../components/modals/ShareModal';
import { FaShareAlt } from 'react-icons/fa';


function Profile() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { userInfo } = useSelector((state) => state.userLogin);
    const [imageUrl, setImageUrl] = useState(userInfo ? userInfo.image : " ")
    const { isLoading, isError, isSuccess } = useSelector((state) => state.userProfileUpdate);
    const { isLoading: deleteLoading, isError: delErr, } = useSelector((state) => state.userDelete);
    const [referralLink, setReferralLink] = useState(`https://plixx.co.in/register?ref=${userInfo ? userInfo.referralCode : " "}`)
    const [modalOpen, setModalOpen] = useState(false)

    //validate user

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(ProfileUpdateValidation)
    })
    //on Submit
    const onSubmit = (data) => {
        dispatch(updateProfileAction({ ...data, image: imageUrl }))
    }

    const onDelete = () => {
        window.confirm("Are you sure you want to delete your account?") &&
            dispatch(deleteProfileAction())
        dispatch(logoutAction())
        navigate("/login")
    }

    useEffect(() => {
        if (userInfo) {
            setValue("name", userInfo.name)
            setValue("email", userInfo.email)
        }
        if (isSuccess) {
            dispatch({ type: "USER_PROFILE_UPDATE_RESET" })
        }
        if (isError || delErr) {
            toast.error(isError || delErr)
            dispatch({ type: "USER_PROFILE_UPDATE_RESET" })
            dispatch({ type: "USER_DELETE_RESET" })

        }

    }, [userInfo, setValue, isSuccess, dispatch, isError, delErr]);
    return (
        <>
            <SEO
                title={`Profile | ${userInfo ? userInfo.name : "User"}`}
                description="Update your profile"
                type="Plixx | Profile"
                name={userInfo ? userInfo.name : "User"}
                keywords={`profile, ${userInfo ? userInfo.name : "User"}, plixx, plixx.com, plixx.co.in`}
            />
            <Sidebar>
            <ShareMovieModal modalOpen={modalOpen} setModalOpen={setModalOpen}  referral={referralLink}/>
                <div className='lg:mx-36'>
                <Stats referralCode={userInfo ? userInfo.referralCode : " "} />
                </div>
                <div className='col-span-6 rounded-md bg-dry lg:border lg:border-gray-800 p-3 lg:p-6 lg:mx-44'>
                    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6'>
                        <h2 className='text-xl font-bold'>Profile</h2>
                        <div className='flex w-full grid lg:grid-cols-12 gap-6'>
                            <div className='flex col-span-10 lg:pr-0 '>
                                <Uploader setImageUrl={setImageUrl} />
                            </div>
                            <div className=' col-span-2'>
                                <ImagePreview image={imageUrl} name={
                                    userInfo ? userInfo.name : "User"
                                } />
                            </div>
                        </div>

                        <div className='w-full'>
                            <Input
                                label="Full Name"
                                placeholder="Enter your Full Name"
                                type="text"
                                name="name"
                                register={register("name")}
                                bg={true}
                            />
                            {
                                errors.name && <InlineError
                                    text={errors.name.message} />

                            }
                        </div>
                        <div className='w-full'>
                            <Input
                                label="Email"
                                placeholder="Plix@gmail.com"
                                type="email"
                                name="email"
                                register={register("email")}
                                bg={true}
                            />
                            {
                                errors.email && <InlineError
                                    text={errors.email.message} />

                            }
                        </div>
                        <div className='w-full'>
                            <label className='block text-gray-700 text-sm font-bold mb-2'>Referral Link</label>
                            <div className='flex'>
                                <input
                                    type="text"
                                    readOnly
                                    className='shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline'
                                    value={referralLink}
                                />
                                <button
                                    type='button'
                                    className='ml-2 py-2 px-3 bg-blue-500 text-white rounded'
                                    onClick={() => {
                                        navigator.clipboard.writeText(referralLink)
                                        toast.success("Copied to Clipboard")

                                    }
                                    }
                                >
                                    Copy to Clipboard
                                </button>
                                <button
                                    type='button'
                                    className='ml-2 py-2 px-10 bg-blue-500 text-white rounded'
                                    onClick={() => setModalOpen(true)}
                                >
                                    Share
                                   
                                </button>
                            </div>
                        </div>
                        <div className='flex gap-2 flex-wrap flex-col-reverse sm:flex-row justify-between items-center my-4 '>
                            <button
                                type='submit'
                                disabled={deleteLoading || isLoading}
                                className='bg-subMain transitions  text-black hover:text-white hover:bg-pinkColor py-3 px-6 rounded w-full sm:w-auto'>
                                {
                                    isLoading ? "Updating..." : "Update Profile"
                                }
                            </button>
                            <button
                                onClick={(() => navigate('/password'))}
                                className='bg-subMain transitions  text-black hover:text-white hover:bg-pinkColor py-3 px-6 rounded w-full sm:w-auto'>
                                Change Password
                            </button>
                        </div>
                    </form>
                </div>
            </Sidebar>
        </>
    )
}

export default Profile