import React from 'react'
import { FaUserFriends } from 'react-icons/fa'
import { Autoplay } from 'swiper'
import { Swiper,SwiperSlide } from 'swiper/react'
import { UserData } from '../../data/UserData'
import Title from '../Title'

function MovieCast({movie}) {
  return (
    movie?.casts?.length > 0 && (
      <div className='my-12'>
      <Title title="Casts" Icon={FaUserFriends} />
      <div className='mt-10'>
        <Swiper autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }} loope={true} speed={1000} modules={[Autoplay]}
          breakpoints={{
            0: {
              slidesPerView: 1
            },
            400: {
              slidesPerView: 2
            },
            768: {
              slidesPerView: 3
            },
            1024: {
              slidesPerView: 4 
            },
            1280: {
              slidesPerView: 5,
              spaceBetween:10
            }
          }} >
            {
              movie?.casts?.map((cast) => (
                <SwiperSlide key={cast?._id}>
                  <div className='w-full p-3 italic text-xs text-text rounded flex flex-col justify-center items-center bg-dry border border-gray-800'>
                    <img src={cast?.image ? `${cast.image}` : '/images/user2.png'} alt={cast?.name} className='w-full h-64 object-cover rounded mb-4'/>
                  <p>{cast?.name}</p>
                  </div>
                </SwiperSlide>
              ))
            }
          </Swiper>
      </div>
    </div>
    )
   
  )
}

export default MovieCast