import React from 'react'
import Title from '../Title'
import { BsCollectionFill } from 'react-icons/bs'
import Movie from '../Movie'
import { Movies } from '../../data/MoviesData'
import Loader from '../Notifications/Loader'
import Empty from '../Notifications/Empty'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react'
import { AiOutlineLeft, AiOutlineRight } from 'react-icons/ai'
import { Autoplay, Navigation } from 'swiper'

function HollywoodMovies({ isLoading, movies }) {
  const [nextEl, setNextEl] = useState(null)
  const [prevEl, setPrevEl] = useState(null)
  return (
    <div className='mb-6 lg:my-12'>
      <Title title='Hollywood Movies' />
      <Swiper
        navigation={{ nextEl, prevEl }}
        modules={[Navigation, Autoplay]}
        breakpoints={{
          0: {
            slidesPerView: 3,
            spaceBetween: 2
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 2
          },
          1024: {
            slidesPerView: 5,
            spaceBetween: 2
          },
          1280: {
            slidesPerView: 5,
            spaceBetween: 2
          }
        }}
        className="mt-5 z-0"
      >
        {
          isLoading ? (
            <Loader />
          ) :
            movies?.length > 0 ? (
              <div className='grid sm:mt-12 mt-30 lg:mt-30 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 z-0 '>
                {
                  movies?.slice(0, 8).map((movie, index) => (
                    <SwiperSlide>
                      <Movie key={index} movie={movie} />
                    </SwiperSlide>
                  ))
                }
              </div>
            ) : (
              <div className='mt-6'>
                <Empty message="No Movies Found" />
              </div>
            )
        }
        <div className='absolute w-full flex flex-row z-50 inset-x-0 top-[30%] gap-[93%] cursor-pointer '>
          <button className="text-white invisible lg:visible " ref={(node) => setPrevEl(node)}>
            <AiOutlineLeft size={50} className="pr-3 hover:scale-150" />
          </button>
          <button classNames="text-white ml-[60%]" ref={(node) => setNextEl(node)}>
            {/* use AiOutlineRIght and on hover its size should increase*/}
            <AiOutlineRight size={50} className=" hover:scale-150 invisible lg:visible" />
          </button>
        </div>
      </Swiper>
    </div>
  )
}

export default HollywoodMovies