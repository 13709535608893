import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { FaHeart, FaPlay } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { LikeMovie, MovieLiked } from '../context/Functionalities'
import { motion } from 'framer-motion'
import { BsPlayCircle } from 'react-icons/bs'
import FlexMovieItem from './FlexMovieItem'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { deleteAllLikedMoviesAction } from '../Redux/Actions/userAction'
import FlexPseItem from './FlexPseItem'


function PornCard({ movie }) {
    const navigate = useNavigate();
    const { isLoading } = useSelector((state) => state.userLikeMovie)
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.userLogin)

    const [isPlaying, setIsPlaying] = useState(false);


    //if liked movie
    const isLiked = (movie) => {
        return MovieLiked(movie);
    };
    //movie like
    const { isLoading: likeLoading } = useSelector(
        (state) => state.userLikeMovie
    );

    const {
        delError,
        isSuccess
    } = useSelector((state) => state.userDeleteAllFavs)

    const deleteFavsHandler = () => {
        dispatch(deleteAllLikedMoviesAction())
    }

    useEffect(() => {
        if (delError) {
            toast.error(delError)
            dispatch({ type: delError ? "USER_GET_FAVOURITE_MOVIE_RESET" : "USER_DELETE_FAVOURITE_MOVIES_RESET" })
        }

    }, [dispatch, delError, isSuccess])


    return (
        <>
            <Link to={`/watch-porn/${movie?.id}`}
            >
                <div className='transition duration-300 ease-in relative  overFlow-hidden z-0 lg:block hover:scale-110 hover:z-50'>
                    <div className='grid grid-rows-2 rounded-md hover:border'>
                        <div>
                        <video
                                src={movie?.preview_url}
                                poster={movie?.default_thumb_url ? `${movie.default_thumb_url}` : '/images/user2.png'}
                                alt={movie?.name}
                                className='lg:w-full w-full lg:h-48 h-24 object-cover rounded-tr-lg rounded-tl-lg bg-dry'
                                onMouseEnter={() => setIsPlaying(true)}
                                onMouseLeave={() => setIsPlaying(false)}
                                onTouchStart={() => setIsPlaying(true)}
                                onTouchEnd={() => setIsPlaying(false)}
                                autoPlay={isPlaying}
                                muted
                                loop
                            />

                            {/* <img src={movie?.default_thumb_url ? `${movie.default_thumb_url}` : '/images/user2.png'}
                                alt={movie?.name} className='lg:w-full w-full lg:h-48 h-24 object-cover rounded-tr-lg rounded-tl-lg bg-dry'
                            /> */}
                            <BsPlayCircle size={36} className='absolute bottom-32 left-16 bg-black opacity-70 rounded-full lg:hidden block' />
                        </div>
                        <div className='bg-[#141414] px-4 lg:pt-4 pt-2 rounded-br-lg rounded-bl-lg '>
                            <div className='grid lg:grid-cols-[80%_10%_10%] grid-cols-1 lg:pr-0 pr-1'>
                                <div>
                                    <p class="text-white font-bold lg:text-lg lg:line-clamp-3 line-clamp-3">{movie?.title}</p>
                                </div>
                                <div className='lg:block hidden'>
                                    <motion.button
                                        whileHover={{ scale: 1.3 }} whileTap={{ scale: 0.7 }}
                                        onClick={() => LikeMovie(movie, dispatch, userInfo)}
                                        disabled={isLiked(movie) || likeLoading}
                                        className={` bg-white hover:scale-125 transitions  px-2 py-2 rounded-full text-sm bg-opacity-10 
                                ${isLiked(movie) ? "text-[#FF0000]" : "text-white"
                                            }`}
                                    >
                                        <FaHeart size={10} />
                                    </motion.button>
                                </div>
                                <div className='lg:pl-2 pl-1 lg:block hidden'>
                                    <button className='bg-white hover:scale-125 transitions text-white px-2 py-2 rounded-full text-sm bg-opacity-10'>
                                        <FaPlay size={10} />
                                    </button>
                                </div>
                            </div>
                            {/* <div className='flex items-center gap-4 font-medium text-dryGray pt-2 lg:flex hidden'>
                                <FlexPseItem movie={movie && movie} />
                            </div> */}
                            {/* <div className='lg:pt-4'>
                            <p className='text-gray-600 text-sm lg:line-clamp-2 line-clamp-2'>{movie?.previewVideo}</p>
                        </div> */}
                        </div>
                    </div>
                </div>
            </Link >
        </>
    )
}

export default PornCard