import React, { useEffect, useState } from 'react'
import Layout from './../layout/Layout'
import Head from '../components/Head'
import { FiMail, FiPhoneCall, FiMapPin, FiVoicemail } from 'react-icons/fi'
import { InlineError } from '../components/Notifications/Error'
import { Input, InputContact, InputText } from '../components/UsedInput'
import { CgWebsite } from 'react-icons/cg'
import { Icon } from '@iconify/react';

function ContactUs() {

  const [longitude, setLongitude] = useState(0);
  const [latitude, setLatitude] = useState(0);


  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLatitude(position.coords.latitude);
      setLongitude(position.coords.longitude);
    }
    );
  }, [])

  let mapUrl = `https://maps.google.com/maps?q=${latitude},${longitude}&z=15&output=embed`

  return (
    <Layout>
      <div classNameName='min-height-screen container mx-auto px-2 my-1'>
        <h1 className='lg:text-5xl text-3xl font-bold text-center px-4'>Contact Us</h1><br />
        <p className='text-center text-gray-500 lg:text-xl text-lg px-4'>Got a technical issue? Want to send feedback about a beta feature? Need</p>
        <p className='text-center text-gray-500 lg:text-xl text-lg lg:mt-3 px-4'>details about our Business plan? Let us know.</p>
        <div className="px-4">
          <div className="container mx-auto">
            <div className="grid lg:grid-cols-2 flex items-center">
              <div 
               data-aos="fade-up"
               data-aos-duration="1000"
               data-aos-delay='10'
               data-aos-offset='200'
              className="md:mt-12 lg:mt-0  lg:mb-0">
                <div className="py-12 md:px-12 ">
                  <form>
                    <div className='w-full mb-6'>
                      <InputContact
                        label="Name"
                        placeholder="Plixx"
                        type="text"
                        name="name"
                        // register={register("email")}
                        bg={true}
                      />
                    </div>
                    <div className='w-full mb-6'>
                      <InputContact
                        label="Email"
                        placeholder="plixx@gmail.com"
                        type="email"
                        name="email"
                        // register={register("email")}
                        bg={true}
                      />
                    </div>
                    <div className='w-full mb-8'>
                      <InputText
                        label="Message"
                        placeholder="Type your message here..."
                        type="text"
                        name="message"
                        // register={register("email")}
                        bg={true}
                      />
                    </div>
                    <div
                      // disabled={isLoading}
                      type='submit'
                      className=''>
                      <button className="w-full px-6 py-3 bg-subMain hover:bg-pinkColor text-black font-bold hover:text-white font-medium rounded hover:shadow-lg transition duration-150 ease-in-out">
                        {/* // className='bg-main transitions hover:bg-subMain border border-subMain text-white py-3 px-6 rounded w-full sm:w-auto'> */}
                        {/* {
                          isLoading ? "Sending..." : "Send"
                        } */}
                        <h1 className='font-bold'>Send</h1>
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="md:mb-12 lg:mb-16 lg:mt-16 mb-6">
                <div className="grid lg:grid-cols-3 flex items-center mb-6 gap-3">
                  <div className='flex flex-row items-center'>
                    <Icon icon="logos:google-gmail" />
                    <span className="text-gray-500 ml-3">alencolins@gmail.com</span>
                  </div>
                  <div className='flex flex-row items-center'>
                    <Icon icon="twemoji:mobile-phone-with-arrow" className='text-xl' />
                    <p className="text-gray-500 ml-3">+91 9644377621</p>
                  </div>
                  <div className='flex flex-row items-center'>
                    <Icon icon="logos:chrome" className='text-xl' />
                    <p className="text-gray-500 ml-3">www.sanketbagad.co.in</p>
                  </div>
                </div>
                <div className="h-[600px] -z-5 relative shadow-lg rounded-lg">
                  <iframe src={mapUrl} className="left-0 top-0 h-full w-full absolute rounded-lg" frameborder="0" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactUs