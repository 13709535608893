import React, { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Input, Inputlogin } from '../components/UsedInput'
import Layout from '../layout/Layout'
import { FiLogIn } from 'react-icons/fi'
import GoogleButton from 'react-google-button'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { LoginValidation } from "../components/Validation/UserValidation"
import { yupResolver } from '@hookform/resolvers/yup'
import { InlineError } from '../components/Notifications/Error'
import { loginAction } from '../Redux/Actions/userAction'
import toast from 'react-hot-toast'
import HalfLayout from '../layout/HalfLayout'
import { SignInWithGoogle } from '../firebase/config'
import SEO from '../SEO/Seo'
import { BsArrowRight } from 'react-icons/bs'
import { FaArrowRight } from 'react-icons/fa'
import Footer from '../layout/footer/Footer'
import Promos from '../components/home/Promos'
function Login() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { isLoading, isError, userInfo, isSuccess } = useSelector((state) => state.userLogin);

    // create a function to handle Sign in with Google and get the user data from local storage
    const handleGoogleSignIn = () => {
        SignInWithGoogle()
    }

    let googleInfo = JSON.parse(localStorage.getItem("userInfo"))

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(LoginValidation)
    })

    //on Submit
    const onSubmit = (data) => {
        dispatch(loginAction(data))
    }

    useEffect(() => {


        if (userInfo?.isAdmin || googleInfo?.isAdmin) {
            navigate("/dashboard");
        }
        else if (userInfo || googleInfo) {
            navigate("/profile");
        }
        if (isSuccess) {
            toast.success(`Welcome Back ${userInfo.name}`
            )
        }
        if (isError) {
            toast.error(isError)
            dispatch({ type: "USER_LOGIN_RESET" })

        }
    }, [isSuccess, isError, userInfo, navigate, dispatch, googleInfo])


    return (
        <>
            <SEO
                title="Login | Plix"
                description="Login to your Plix account"
                name="Plix"
                type="website"
                keywords="Login, Plix, Plix Login, Plix Account, Plix Account Login, Free Plix Acc, plixx.co.in, plixx.co.in login, plixx.co.in account, plixx.co.in account login, plixx.co.in"
            />
            <div className='mx-auto bg-main'>
                <section class="w-full h-screen bg-center bg-cover bg-tnj-one opacity-30" />
                <div className='px-2 absolute top-0 z-30 '>
                    <div className='grid grid-cols-2'>
                        <div>
                            <Link to="/">
                                <img
                                    src='../../images/updatedLogo3.png'
                                    alt='logo'
                                    className='lg:w-36 w-20 lg:h-20 h-12 object-contain'
                                />
                            </Link>
                        </div>
                    </div>
                </div>
                {/* <div className='absolute min-height-screen container mx-auto px-2 top-40 lg:top-54'>
                    <div className='flex items-center justify-center'>
                        <img
                            src='../../images/lloogo.png'
                            alt='logo'
                            className='w-full h-12 object-contain'
                        />
                    </div>
                    <div className='flex items-center justify-center '>
                        <h1 className='text-2xl font-bold text-center text-white mt-6'>Get Plixx, both with ads, for $9.99/month.</h1>
                    </div>
                    <div className='flex items-center justify-center mt-10'>
                        <a href='/premium' className='w-60 flex items-center justify-center inline-block border-subMain font-bold px-6 py-3 bg-subMain text-white text-lg leading-tight uppercase rounded shadow-md hover:bg-subMain hover:shadow-lg focus:bg-sub focus:shadow-lg focus:outline-none focus:ring-0 active:shadow-lg transition duration-150 ease-in-out '>
                            Get Premium
                        </a>
                    </div>
                    <div className='flex items-center justify-center mt-10'>
                        <a href='/' className='text-white text-lg underline'>Terms and Condition</a>
                    </div>
                    <div className='flex items-center justify-center mt-10'>
                        <a href='/' className='text-white text-xl underline'>Sign Up for free</a> &nbsp;&nbsp;
                    </div>
                </div> */}
                <div className='container  absolute top-20  lg:bottom-0 flex lg:px-32 px-4 flex-col justify-center items-center opacity-80'>
                   
                    <form onSubmit={handleSubmit(onSubmit)} className='w-full w-1/5 2xl:w-2/5 gap-8 lg:px-10 lg:py-6 px-4 py-2 md:w-3/5 flex flex-col justify-center items-center bg-dry '>
                        <img
                            src='../../images/updatedLogo3.png'
                            alt='logo'
                            className='w-full lg:h-16 h-12 object-contain'
                        />
                        <div className='w-full'>
                            <Inputlogin
                                label="Email"
                                placeholder="plix@gmail.com"
                                type="email"
                                name="email"
                                register={register("email")}
                                bg={true}
                            />
                            {
                                errors.email && <InlineError
                                    text={errors.email.message} />

                            }
                        </div>
                        <div className='w-full'>
                            <Inputlogin
                                label="Password"
                                placeholder="*********"
                                type='password'
                                name="password"
                                register={register("password")}
                                bg={true}
                            />
                            {
                                errors.password && <InlineError
                                    text={errors.password.message} />

                            }
                        </div>

                        <button
                            type='submit'
                            disabled={isLoading}
                            className='bg-blueColor hover:bg-pinkColor font-bold text-lg transitions hover:bg-main flex flex-row justify-center items-center gap-4 text-Black hover:text-white p-3 rounded-lg w-full'>

                            {
                                isLoading ? "Loading..." : (
                                    <>
                                        <FiLogIn />
                                        <h1 className='font-bold'>Sign In</h1>
                                    </>
                                )
                            }
                        </button>
                        {/* a button ui in tailwind css for google login */}
                        <button
                            onClick={handleGoogleSignIn}
                            className='bg-white flex flex-row justify-center items-center gap-4 text-black p-3 rounded-lg w-full'>
                            <img
                                src='/images/google.png'
                                alt='google'
                                className='w-6 h-6 object-contain'
                            />
                            Sign In with Google
                        </button>
                        <p className='text-center text-gray-400'>
                            Don't have an account?{" "}
                            <Link to="/register" className='text-gray-400 font-semibold ml-2'>
                                Sign Up
                            </Link>
                        </p>
                    </form>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default Login