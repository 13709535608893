export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";


export const USER_DELETE_REQUEST = "USER_DELETE_REQUEST";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const USER_DELETE_RESET = "USER_DELETE_RESET";


export const USER_CHANGE_PASSWORD_REQUEST = "USER_CHANGE_PASSWORD_REQUEST";
export const USER_CHANGE_PASSWORD_SUCCESS = "USER_CHANGE_PASSWORD_SUCCESS";
export const USER_CHANGE_PASSWORD_FAIL = "USER_CHANGE_PASSWORD_FAIL";
export const USER_CHANGE_PASSWORD_RESET = "USER_CHANGE_PASSWORD_RESET";

export const USER_GET_FAVOURITE_MOVIE_REQUEST = "USER_GET_FAVOURITE_MOVIE_REQUEST";
export const USER_GET_FAVOURITE_MOVIE_SUCCESS = "USER_GET_FAVOURITE_MOVIE_SUCCESS";
export const USER_GET_FAVOURITE_MOVIE_FAIL = "USER_GET_FAVOURITE_MOVIE_FAIL";
export const USER_GET_FAVOURITE_MOVIE_RESET = "USER_GET_FAVOURITE_MOVIE_RESET";

export const USER_DELETE_FAVOURITE_MOVIES_REQUEST = "USER_DELETE_FAVOURITE_MOVIES_REQUEST";
export const USER_DELETE_FAVOURITE_MOVIES_SUCCESS = "USER_DELETE_FAVOURITE_MOVIES_SUCCESS";
export const USER_DELETE_FAVOURITE_MOVIES_FAIL = "USER_DELETE_FAVOURITE_MOVIES_FAIL";
export const USER_DELETE_FAVOURITE_MOVIES_RESET = "USER_DELETE_FAVOURITE_MOVIES_RESET";

export const USER_ADD_FAVOURITE_MOVIE_REQUEST = "USER_ADD_FAVOURITE_MOVIE_REQUEST";
export const USER_ADD_FAVOURITE_MOVIE_SUCCESS = "USER_ADD_FAVOURITE_MOVIE_SUCCESS";
export const USER_ADD_FAVOURITE_MOVIE_FAIL = "USER_ADD_FAVOURITE_MOVIE_FAIL";
export const USER_ADD_FAVOURITE_MOVIE_RESET = "USER_ADD_FAVOURITE_MOVIE_RESET";

// remove fav
export const USER_REMOVE_FAVOURITE_MOVIE_REQUEST = "USER_REMOVE_FAVOURITE_MOVIE_REQUEST";
export const USER_REMOVE_FAVOURITE_MOVIE_SUCCESS = "USER_REMOVE_FAVOURITE_MOVIE_SUCCESS";
export const USER_REMOVE_FAVOURITE_MOVIE_FAIL = "USER_REMOVE_FAVOURITE_MOVIE_FAIL";
export const USER_REMOVE_FAVOURITE_MOVIE_RESET = "USER_REMOVE_FAVOURITE_MOVIE_RESET";

// get user
export const GET_ALL_USERS_REQUEST = "GET_ALL_USERS_REQUEST";
export const GET_ALL_USERS_SUCCESS = "GET_ALL_USERS_SUCCESS";
export const GET_ALL_USERS_FAIL = "GET_ALL_USERS_FAIL";
export const GET_ALL_USERS_RESET = "GET_ALL_USERS_RESET";

//delete user
export const ADMIN_DELETE_USER_REQUEST = "ADMIN_DELETE_USER_REQUEST";
export const ADMIN_DELETE_USER_SUCCESS = "ADMIN_DELETE_USER_SUCCESS";
export const ADMIN_DELETE_USER_FAIL = "ADMIN_DELETE_USER_FAIL";
export const ADMIN_DELETE_USER_RESET = "ADMIN_DELETE_USER_RESET";

//like movie
export const USER_LIKE_MOVIE_REQUEST = "USER_LIKE_MOVIE_REQUEST";
export const USER_LIKE_MOVIE_SUCCESS = "USER_LIKE_MOVIE_SUCCESS";
export const USER_LIKE_MOVIE_FAIL = "USER_LIKE_MOVIE_FAIL";
export const USER_LIKE_MOVIE_RESET = "USER_LIKE_MOVIE_RESET";

//referred User
export const REFERRED_USER_REQUEST = "REFERRED_USER_REQUEST";
export const REFERRED_USER_SUCCESS = "REFERRED_USER_SUCCESS";
export const REFERRED_USER_FAIL = "REFERRED_USER_FAIL";
export const REFERRED_USER_RESET = "REFERRED_USER_RESET";


