import React from 'react'
import { BsFillGridFill } from 'react-icons/bs'
import { FaHeart, FaListAlt, FaUser } from 'react-icons/fa'
import { RiLockPasswordLine, RiLogoutCircleLine, RiMovie2Fill } from 'react-icons/ri'
import { NavLink, useNavigate } from 'react-router-dom'
import { HiViewGridAdd } from 'react-icons/hi'
import { FiSettings } from 'react-icons/fi'
import Layout from '../../layout/Layout'
import { useDispatch, useSelector } from 'react-redux'
import { logoutAction } from '../../Redux/Actions/userAction'
import { toast } from 'react-hot-toast'


function Sidebar({ children }) {

    const active = "bg-dryGray text-subMain"
    const hover = "hover:text-white hover:bg-main"
    const inActive = "rounded font-medium text-sm transitions flex gap-3 items-center p-4"
    const Hover =({isActive}) =>
        isActive ?`${active} ${inActive}` : `${inActive} ${hover}`;
    
    
    return (
        <Layout>
            <div className='min-h-screen container mx-auto px-2'>
                <div className='xl:grid grid-cols-8 gap-10 items-start md:py-12 py-6'>
                    <div 
                    data-aos="fade-up"
                    data-aos-duration="1000"
                    data-aos-delay='10'
                    data-aos-offset='200'
                    className='col-span-10 rounded-md '>
                        {children}
                        </div>
                </div>
            </div>


        </Layout>
    )
}

export default Sidebar