import { Icon } from '@iconify/react'
import React from 'react'
import { Link } from 'react-router-dom'

function Footer() {
  const Links = [
    {
      title: 'Company',
      links: [
        { name: 'Home', link: '/' },
        { name: 'About Us', link: '/about-us' },
        { name: 'Movies', link: '/movies' },

      ]
    },
    {
      title: 'Top-Categories',
      links: [
        { name: 'Action', link: '#' },
        { name: 'Romantic', link: '#' },
        { name: 'Drama', link: '#' },
        { name: 'Historical', link: '#' },

      ]
    },
    {
      title: 'My Account',
      links: [
        { name: 'Dashboard', link: '/dashboard' },
        { name: 'My Favourite', link: '/favourite' },
        { name: 'Profile', link: '/profile' },
        { name: 'Change Password', link: '/password' },

      ]
    }
  ]
  return (
    <div className='bg-dry py-4 border=t-2 border-black' >
      <div className='container mx-auto px-2'>
        <div className='grid grid-cols-2 md:grid-cols-7 xl:grid-cols-12 gap-5 sm:gap-9 lg:gap-11 xl:gap-7 py-10 justify-between '>
          {Links.map((link, index) => (
            <div key={index} className='col-span-1 md:col-span-2 xl:col-span-3 pb-3.5 sm:pb-0 '>
              <h3 className='text-md lg:leading-7 font-medium mb-4 sm:mb-5 lg:md-6 pb-0.5 text-white'>{link.title}</h3>
              <ul className='text-sm flex flex-col space-y-3'>
                {link.links.map((text, index) => (
                  <li key={index} className="flex item-baseline">
                    <Link to={text.link} className='text-gray-400 inline-block w-full hover:text-subMain'>
                      {text.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>

          ))}
          <div className='sm:pb-0 col-span-1 md:col-span-2 lg:col-span-3 -mt-6'>
            <Link to="/">
              <img
                src='../../images/updatedLogo3.png'
                alt='logo'
                className='lg:w-36 w-2/4 lg:h-20 h-12 object-contain'
              />
            </Link>
            <p className='leading-8 text-sm text-gray-400 -mt-3'>
              <span>
                35 Mombay Plot Road, <br /> Khetia, 451881
              </span>
              <br />
              <div className='flex flex-row items-center mt-2'>
                <Icon icon="twemoji:mobile-phone-with-arrow" className='text-xl' />
                <p className="text-sm text-gray-400 ml-2">+91 9644377621</p>
              </div>
              <div className='flex flex-row items-center mt-2'>
                <Icon icon="logos:google-gmail" />
                <span className="leading-5 text-sm text-gray-400 ml-2">alencolins@gmail.com</span>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer