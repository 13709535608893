import React from 'react'
import Title from '../Title'
import { BsCaretLeftFill, BsCaretRightFill, BsCollectionFill } from 'react-icons/bs'
import Movie from '../Movie'
import { Movies } from '../../data/MoviesData'
import Loader from '../Notifications/Loader'
import Empty from '../Notifications/Empty'
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react'
import { AiFillLock, AiOutlineLeft, AiOutlineLeftCircle, AiOutlineRight, AiOutlineRightCircle } from 'react-icons/ai'
import { Autoplay, Navigation } from 'swiper'
import { Link, useNavigate } from 'react-router-dom'
import Rating from '../Stars'

function PremiumMovies({ isLoading, movies }) {
    const [nextEl, setNextEl] = useState(null)
    const [prevEl, setPrevEl] = useState(null)
    const navigate = useNavigate();
    return (
        <div className='my-12'>
            <Title title='Premium Movies' />
            <Swiper
                navigation={{ nextEl, prevEl }}
                modules={[Navigation, Autoplay]}
                breakpoints={{
                    0: {
                        slidesPerView: 2,
                        spaceBetween: 2
                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 2
                    },
                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 2
                    },
                    1280: {
                        slidesPerView: 4,
                        spaceBetween: 2
                    }
                }}
                className="mt-5 z-0"
            >
                {
                    isLoading ? (
                        <Loader />
                    ) :
                        movies?.length > 0 ? (
                            <div className='grid sm:mt-12 mt-30 lg:mt-30 xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-10 z-0 '>

                                {
                                    movies?.slice(0, 8).map((movie, index) => (
                                        <SwiperSlide key={movie?._id}>
                                            <div key={movie?._id} className='h-60 hover:border-b-4 hover:border-borderr transition duration-800 ease-in bg-dry rounded-lg overflow-hidden hover:scale-90 cursor-pointer'>
                                                <img src={movie?.titleImage ? `${movie.titleImage}` : '/images/user2.png'}
                                                    alt={movie.titleImage} className='w-full h-60 object-cover rounded-lg' />
                                                <Link to="/premium">
                                                    <div className='px-4 gap-6 text-center absolute bg-black bg-opacity-70  top-0 left-0 right-0 bottom-0 pt-20'>
                                                        <button
                                                            onClick={() => navigate('/premium')}
                                                            className='w-12 h-12 flex-colo transitions hover:bg-pinkColor rounded-full bg-white bg-opacity-30 text-white pl-4'>
                                                            <AiFillLock />
                                                        </button>
                                                    </div>
                                                </Link>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                }
                            </div>
                        ) : (
                            <div className='mt-6'>
                                <Empty message="No Movies Found" />
                            </div>
                        )
                }
                <div className='absolute w-full flex flex-row z-50 inset-x-0 top-[30%] lg:gap-[93%] sm:gap-y-36'>
                    <button className="text-white invisible lg:visible" ref={(node) => setPrevEl(node)}>
                        <AiOutlineLeft size={50} className="pr-3 hover:scale-150" />
                    </button>
                    <button classNames="text-white lg:ml-[60%] ml-[20%]" ref={(node) => setNextEl(node)}>
                        {/* use AiOutlineRIght and on hover its size should increase*/}
                        <AiOutlineRight size={50} className=" hover:scale-150 invisible lg:visible" />
                    </button>
                </div>
            </Swiper>
        </div>
    )
}

export default PremiumMovies