import React, { useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { FaCloudDownloadAlt, FaEdit, FaHeart, FaPlay } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { DownloadVideo, LikeMovie, MovieLiked } from '../context/Functionalities'
import { GoEye } from 'react-icons/go'
import Rating from './Stars'
import { motion } from 'framer-motion'
import { BsPlayCircle } from 'react-icons/bs'
import FlexMovieItem from './FlexMovieItem'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import { deleteAllLikedMoviesAction } from '../Redux/Actions/userAction'
import { MdDelete } from 'react-icons/md'
import { SidebarContext } from '../context/DrawerContext'
import { useContext } from 'react'
import FileSaver from 'file-saver'


function EditDeleteMovies({ movie, onDeleteHandler, admin }) {
    const navigate = useNavigate();
    const { isLoading } = useSelector((state) => state.userLikeMovie)
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.userLogin)
  const {progress, setProgress} = useContext(SidebarContext)


    const downloadMovie = async (videoUrl,name) => {
        await DownloadVideo(videoUrl, setProgress).then((data)=>{
          setProgress(0);
          FileSaver.saveAs(data, name);
        });
      }


    return (
        <>
            <div className='transition duration-300 ease-in relative  overFlow-hidden z-0 lg:hidden block bg-[#141414]'>
                <div class="relative flex w-full overflow-hidden ">
                    <div>
                        <Link to={`/movie/${movie?._id}`} >
                            <img src={movie?.image ? `${movie.image}` : '/images/user2.png'}
                                alt={movie?.name} className='lg:w-72 w-52 lg:h-44 h-24 object-cover'
                            />
                            <BsPlayCircle size={32} className='absolute bottom-1 left-1 bg-black opacity-70 rounded-full lg:hidden block' />
                        </Link>
                    </div>
                    <div class="w-2/3 lg:hidden block">
                        <div>
                            <p class="text-white font-bold lg:text-2xl text-sm pl-2">{movie?.name}</p>
                        </div>
                        <div className='grid grid-cols-[20%_80%] pl-2'>
                            <div>
                                <p class="mt-2 text-gray-400 text-sm  lg:hidden block">{movie?.year}</p>
                            </div>
                            <div >
                                <p class="mt-2 text-gray-400 text-sm lg:hidden block pl-2">{movie.time} Hr</p>
                            </div>
                        </div>
                        <div>
                            <p className='mt-2 text-gray-400 text-sm lg:hidden block pl-2'>{movie.category}</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className='transition duration-300 ease-in relative  overFlow-hidden z-0 lg:block hidden hover:scale-110 hover:z-50'>
                <div className='grid grid-rows-2 rounded-md hover:border'>
                    <div>
                        <Link to={`/movie/${movie?._id}`} >
                            <img src={movie?.image ? `${movie.image}` : '/images/user2.png'}
                                alt={movie?.name} className='lg:w-full w-52 lg:h-48 h-24 object-cover rounded-tr-lg rounded-tl-lg bg-dry'
                            />
                        </Link>
                    </div>
                    <div className='bg-[#141414] px-4 pt-4 rounded-br-lg rounded-bl-lg'>
                        <div className='grid grid-cols-[80%_10%_10%]'>
                            <div>
                                <p class="text-white font-bold lg:text-lg">{movie?.name}</p>
                            </div>
                            {
                                userInfo?.isAdmin ?
                                    <>
                                        <div>
                                            <NavLink to={`/edit/${movie?._id}`} className=' bg-white flex flex-row justify-center hover:scale-125 items-center gap-2 text-border rounded-full py-2 px-2 bg-opacity-10'
                                            >
                                                <FaEdit size={15} className='text-green-500' />
                                            </NavLink>

                                        </div>
                                        <div className='pl-2'>
                                            <button className='bg-white hover:scale-125 transitions text-white px-2 py-2 rounded-full text-sm bg-opacity-10'
                                                onClick={() => onDeleteHandler(movie?._id)}
                                            >
                                                <MdDelete size={15} />
                                            </button>
                                        </div>
                                    </>
                                    : (
                                        <>
                                         <div>
                                            <button
                                                disabled={progress > 0 && progress < 100}
                                                onClick={() => downloadMovie(movie?.video, movie?.name)}
                                                className='bg-white hover:scale-125 transitions text-white px-2 py-2 rounded-full text-sm bg-opacity-10'>
                                                <FaCloudDownloadAlt size={15} className='text-green-500' />
                                            </button>
                                            </div>
                                            <div className='pl-2'>
                                            <Link to={`/movie/${movie?._id}`} className='bg-white bg-opacity-10  hover:scale-125 text-white rounded-full flex flex-col justify-center items-center w-7 h-7 px-1 py-1'>
                                                <FaPlay />
                                            </Link>
                                            </div>
                                           
                                        </>
                                    )
                            }


                        </div>
                        <div className='flex items-center gap-4 font-medium text-dryGray pt-2'>
                            <FlexMovieItem movie={movie && movie} />
                        </div>
                        <div className='pt-4'>
                            <p className='text-gray-600 text-sm line-clamp-3'>{movie?.desc}</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default EditDeleteMovies